import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject, takeUntil } from 'rxjs';

import { ServiceInvoker } from './service-invoker';
import { ContractDetailsService } from './contract-details.service';

@Injectable({
  providedIn: 'root'
})
export class DownloadFileService {

  private unSubscribeSub: Subject<void> = new Subject();
  formattedFileNames: any = [];

  constructor(private dataService: ServiceInvoker, private contractDetailsService: ContractDetailsService) { }

  /**
   * download selected file from S3 bucket
   * @param fileName - selected file name
   * @param id - BRERequirement ID
   * @returns - file data in base64 format
   */
  downloadFile(fileNames: any, id?: string): Observable<any> {
    const subject = new ReplaySubject<any>(1);
    const payload = this.getPayloadData(fileNames, id || '');
    this.dataService.downloadFile(payload)
      .pipe(takeUntil(this.unSubscribeSub))
      .subscribe({
        next: (response: any) => this.handleResponse(response, subject),
        error: () => this.handleError(subject),
      });
    return subject.asObservable();
  }

  private handleResponse(response: any, subject: ReplaySubject<any>): void {
    const processedSubject = this.processResponse(response, subject);
    processedSubject?.complete();
  }

  private handleError(subject: ReplaySubject<any>): void {
    subject.next('500');
    subject.complete();
  }

  /**
   * process response for download file api
   * @param response - response from the api
   * @param subject - subject for success or failure
   * @returns set appropriate subject based on success or failure
   */
  processResponse(response: any, subject: any): any {
    if (response.responseStatus === 'SUCCESS') {
      return subject?.next(response);
    }
    return subject.next('500');
  }

  /**
   * generate payload for download and api call
   * @param fileName - selected file name
   * @param id - BRERequirement ID
   * @returns - payload
   */
  getPayloadData(fileNames: any, id: string): any {
    const contractNumber = this.contractDetailsService.getContractNumber()?.toString();
    const payload: any = {};
    payload.contractNumber = contractNumber;
    const files: any = [];
    fileNames?.split(',')?.forEach((element: any) => {
      const obj = {
        id,
        fileName: element.trim()
      }
      files.push(obj);
    });
    payload.filesToDownload = files;
    return payload;
  }

}

