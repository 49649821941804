import { EntPrimaryTableConfigInterface } from "src/app/shared/models/app.model";
import { TableConfig } from "src/config/constants";

export const FundsReceivedTableConfig: EntPrimaryTableConfigInterface = {
  config: {
    tableId: 'funds-received',
    tableClass: 'funds-received-table',
    tableCaption: 'funds received table',
    ...TableConfig
  },
  column: [
    {
      pipeName: '',
      displayText: 'CLIENT NAME',
      columnKey: 'ClientName',
      isAction: 'noaction',
      type: 'string', // string|number|email
      align: 'left',
      sorting: true,
    },
    {
      pipeName: 'currency',
      displayText: 'RECEIVED AMOUNT',
      columnKey: 'ReceivedAmount',
      isAction: 'noaction',
      type: 'string', // string|number|email
      align: 'right',
      sorting: true,
      classNameTh: 'align-right'
    },
    {
      pipeName: '',
      displayText: 'PAYMENT STATUS',
      columnKey: 'PaymentStatus',
      isAction: 'noaction',
      type: 'string', // string|number|email
      align: 'left',
      sorting: true,
      classNameTh: 'align-right'
    }
  ],
  data: [],
};
