import { HttpClient } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { EntRestClientService } from '@ng/ent-utilities';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { SFS_CONFIG } from 'src/config/constants'

@Injectable({
  providedIn: 'root'
})
export class ServiceInvoker {

  constructor(private rs: EntRestClientService, private http: HttpClient) {}

  private isDev = isDevMode();

  /**
   * get agent profile details by calling this method
   * @param {any} payload:any
   * @returns {any} agent profile details response
   */
  getAgentProfile(payload: any): Observable<any> {
    const url = this.isDev
      ? environment.serviceUrls.agentProfileMock
      : environment.serviceUrls.apiUrl + '/agentProfile';
    return this.rs.makeCall<any>(url, payload);
  }

  /**
   * get contract details by calling this method
   * @param payload
   * @returns contract details for corresponding contract number
   */
  getContractDetails(payload: any): Observable<any> {
    const url = this.isDev
      ? environment.serviceUrls.contractDetailsMock
      : environment.serviceUrls.apiUrl + '/nbpendingcontractdetails';
    return this.rs.makeCall<any>(url, payload);
  }

  /**
   * this method is used for making API call to add comments, comments after uploading file
   * @param payload
   * @returns response from the api call
   */
  resolveRequirements(payload: any): Observable<any> {
    const url = this.isDev
      ? environment.serviceUrls.requirementResolution
      : environment.serviceUrls.apiUrl + '/requirements-resolution';
    return this.rs.makeCall<any>(url, payload);
  }

  /**
   * upload files using SFS API
   * @param payload input file in allowed format
   * @returns response from the api call
   */
  uploadFiles(payload: any): Observable<any> {
    const url = this.isDev
      ? environment.serviceUrls.uploadFiles
      : environment.serviceUrls.sfsApiUrl + '/uploadFileSecure';
    return this.isDev ? this.http.get<any>(url) : this.http.post<any>(url, payload, {
      headers: this.getSFSCallHeaders(),
      reportProgress: true,
      observe: 'events'
    });
  }

  /**
   * verify file to be uploaded for password protection, malware using SFS API
   * @param payload input file in allowed format
   * @returns response from the api call
   */
  pwdProtectFile(payload: any): Observable<any> {
    const url = this.isDev
      ? environment.serviceUrls.uploadFiles
      : environment.serviceUrls.sfsApiUrl + '/uploadFileSecure';
    return this.isDev ? this.http.get<any>(url) : this.http.post<any>(url, payload, { headers: this.getSFSCallHeaders() });
  }

  /**
   * creates header object required for SFS API call
   * @returns header object
   */
  getSFSCallHeaders(): any {
    return {
      'AWS_ENV': environment.env,
      'X-application-name': SFS_CONFIG.HEADER_KEYS.X_APPLICATION_NAME,
      'WSUID': '',
      'APM_ID': SFS_CONFIG.APM_ID,
      'CALLER': SFS_CONFIG.HEADER_KEYS.CALLER,
      'CLIENT': SFS_CONFIG.HEADER_KEYS.CLIENT,
      'CHANNEL': SFS_CONFIG.HEADER_KEYS.CHANNEL
    }
  }

  /**
   * download file from ITES S3 bucket which is uploaded by
   * case coordinator associated with respective BRERequirement
   * @param payload contract number, BRERequirement ID, file name
   * @returns file in base64 format
   */
  downloadFile(payload: any): Observable<any> {
    const url = this.isDev
      ? environment.serviceUrls.downloadFile
      : environment.serviceUrls.apiUrl + '/downloadAgentFiles';
    return this.isDev ? this.http.get<any>(url) : this.rs.makeCall<any>(url, payload);
  }

  /**
   * get the list of all the files associated with each BRERequirement
   * @param contractNumber contract number
   * @returns list of all the files, counter status
   */
  getFileDownloadMetadata(contractNumber: number): Observable<any> {
    const url = this.isDev
      ? environment.serviceUrls.getFileDownloadMetadata
      : environment.serviceUrls.apiUrl + '/fileDownloadMetadata?contractNumber=' + contractNumber;
    return this.http.get<any>(url)
  }
}
