import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { formatCurrency } from '@angular/common';
import { Observable, switchMap, tap } from 'rxjs';

import { EntWindowRefService } from '@ng/ent-utilities';

import { ContractDetailsService } from 'src/app/shared/services/contract-details.service';
import { AgentService } from 'src/app/shared/services/agent.service';
import { UtilsService } from 'src/app/shared/services/utils.service';

import { AgentData, EntPrimaryTableConfigInterface, ResponseStatusInterface,
         RateLockInfoData, ContractData, ContractStatusTracker, BRERequirement,
         FundsReceivedMoneyIn, SourceOfFundsUI, AgentInfo} from 'src/app/shared/models/app.model';

import { AppConstants, Message, ResponseStatus, SECURE_SITES } from 'src/config/constants';
import { ClientInfoTableConfig } from '../client-information/client-info-table-config';
import { AgentInfoTableConfig } from '../agent-information/agent-info-table-config';
import { ContractInfoTableConfig } from '../contract-information/contract-info-table-config';
import { SourceOfFundsTableConfig } from '../source-of-fund/source-of-fund-table-config';
import { FundsReceivedTableConfig } from '../funds-received/funds-received-table-config';
import { AnalyticsAppService } from 'src/app/shared/services/analytics.service';


@Component({
  selector: 'app-policy-details',
  templateUrl: './policy-details.component.html',
  styleUrls: ['./policy-details.component.scss']
})
export class PolicyDetailsComponent implements OnInit  {

  apiStatus$: Observable<string> | undefined;
  message = Message;
  responseStatus: ResponseStatusInterface = ResponseStatus;
  contractNumber!: number;
  clientInfoTableConfig: EntPrimaryTableConfigInterface = ClientInfoTableConfig;
  contractData!: ContractData;
  agentData!: AgentData[];
  agentInfoTableConfig: EntPrimaryTableConfigInterface = AgentInfoTableConfig;
  contractInfoTableConfig: EntPrimaryTableConfigInterface = ContractInfoTableConfig;
  rateLockInfo!: RateLockInfoData;
  sourceOfFundsTableConfig: EntPrimaryTableConfigInterface = SourceOfFundsTableConfig;
  fundsReceivedTableConfig: EntPrimaryTableConfigInterface = FundsReceivedTableConfig;
  contractTrackerData!: ContractStatusTracker[];
  requirementsData!: BRERequirement[];
  noLOA = false;
  notNoLOA = false;
  isAccountLookup: boolean = false;

  win: any = '';
  currentFocus = '';

  constructor(private agentService: AgentService, private contractDetailsService: ContractDetailsService,
    private activatedRoute: ActivatedRoute, private utilService: UtilsService, private analytics: AnalyticsAppService,
    private nativeWindow: EntWindowRefService) {
      this.win = this.nativeWindow.getWindow();
    }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.contractNumber = Number(this.activatedRoute.snapshot.queryParamMap.get('contractNumber')) ?? null;
    this.contractDetailsService.setContractNumber(this.contractNumber);
    this.apiStatus$ = this.agentService.agentProfileSubject.pipe(
      tap((agentResponse: any) => agentResponse ),
      switchMap((agentResponse: any) => agentResponse ? this.contractDetailsService.fetchContractDetails(agentResponse): ''),
      tap((contractRespone: any) => {
        if (contractRespone === this.responseStatus.success) {
          this.loadAppData();
      }}));
    this.agentService.fetchAgentProfile();
    this.isAccountLookup = this.contractDetailsService.getAccountlookupFlag();
  }

  /**
    * Loads data from the contract details service and assigns it to the component properties.
    */
  loadAppData(): void {
    this.contractData = this.contractDetailsService.getContractDetails();
    const clientName = `${this.contractData?.ClientLastName ?? ''}, ${this.contractData?.ClientFirstName ?? ''}`;
    this.utilService.setOwnerName(clientName);
    this.utilService.setBRERequirements(this.contractData.BRERequirement);
    this.utilService.setNIGOComments(this.contractData.NIGOComment);
    this.utilService.setAgentComments(this.contractData.AgentComment);
    this.clientInfoTableConfig.data = this.getClientInfoData();
    this.agentData = this.getAgentInfoData();
    this.agentInfoTableConfig.data = this.agentData;
    this.requirementsData = this.getRequirementData();
    this.contractInfoTableConfig.data = this.getContractInfoData();
    this.rateLockInfo = this.getRateLockInfoData();
    this.sourceOfFundsTableConfig.data = this.getSourceOfFundsInfoData();
    this.fundsReceivedTableConfig.data = this.getFundsReceivedInfoData();
    this.contractTrackerData = this.contractData?.ContractStatusTracker;
    this.checkLOACondition();
  }

  /**
   * Formats client info data for use in the client info table
   *
   * @returns formatted array
   */
  getClientInfoData(): Array<any> {
    const clientInfo = this.contractData?.OwnerAnnuitantInfo || null;
    return clientInfo?.map((data:any) => {
      let clientName = `${data?.LastName ?? ''}, ${data?.FirstName ?? ''}`;
      clientName = this.utilService.getFormattedName(clientName);
      const ssnElement = `<span aria-hidden="true">XXX-XX-${data?.LastFourSSN}</span><span class="screen-reader-only">last four digits of ssn ${data?.LastFourSSN}</span>`;
      return {
        ...data,
        LastFourSSN: data?.LastFourSSN ? ssnElement : '',
        FullName: data?.FullName ? data?.FullName : clientName,
        PhoneNumber: this.utilService.getFormattedPhoneNumber(data.PhoneNumber)
      };
    })
  }


  /**
   * Formats rate lock info data for use in the rate lock info table
   *
   * @returns object with formatted data
   */
  getRateLockInfoData(): any {
    let wait30 = '';
    if (this.contractData?.Wait30 === 'Y') {
      wait30 = 'Elected';
    } else if (this.contractData?.Wait30 === 'N') {
      wait30 = 'Not elected';
    }
    return {
      "effectiveDate": this.contractData?.RateLockEffDate ? this.utilService.getFormattedDate(this.contractData?.RateLockEffDate) : '',
      "duration": this.contractData?.RateLockDuration ? `${this.contractData?.RateLockDuration} Days` : '',
      "expirationDate": this.contractData?.RateLockExpireDate ? this.utilService.getFormattedDate(this.contractData?.RateLockExpireDate) : '',
      "wait30": wait30
    };
  }

  /**
   * Formats agent info data for use in the agent info table
   *
   * @returns array of AgentInfo objects
   */
  getAgentInfoData(): Array<AgentInfo> {
    const agentInfo = this.contractData?.AgentInfo || null;
    return agentInfo?.map((data:any) => {
      const ssnElement = `<span aria-hidden="true">XXX-XX-${data?.Last4AgentSSN}</span><span class="screen-reader-only">last four digits of ssn ${data?.Last4AgentSSN}</span>`;
      return {
        ...data,
        Last4AgentSSN: data?.Last4AgentSSN ? ssnElement : ''
      };
    })
  }

  /**
   * Sets agent info table config to display all rows
   */
  viewAllrows(): void {
    this.agentInfoTableConfig.config.rowsPerPage = this.agentInfoTableConfig.data.length;
    this.setFocus('viewless');
  }

  /**
   * Sets agent info table config to display 3 rows
   */
  viewLessrows(): void {
    this.agentInfoTableConfig.config.rowsPerPage = 3;
    this.setFocus('viewall');
  }

  /**
   * Formats contract info data to use in the contract info table
   * The global table component is designed for row-oriented tables
   * This method maps the data to display in a column-oriented way
   *
   * @returns array of key-value pairs for each row in the table
   */
  getContractInfoData(): Array<any> {
    const issueStateAriaLabel = [...this.contractData?.IssueState || '']?.join(',') || '';
    const rows = {
      "Type": this.contractData?.ProductType,
      "Plan": this.utilService.addTrademarkSymbol(this.contractData?.LegalProductName),
      "Total expected amount": this.contractData?.TotalExpectedAmt || this.contractData?.TotalExpectedAmt == 0 ? formatCurrency(Number(this.contractData?.TotalExpectedAmt),'en-US','$') : '',
      "Application received date": this.contractData?.AppReceivedDate ? this.utilService.getFormattedDate(this.contractData?.AppReceivedDate) : '',
      "Issue state": `<span aria-hidden="true">${this.contractData?.IssueState || ''}</span><span class="screen-reader-only">${issueStateAriaLabel}</span>`,
      "Contract status": this.contractData?.ContractStatus,
      "Client name": this.utilService.getOwnerName()
    }

    return  Object.entries(rows)?.map(([key,val])=>{
      return {
        label:key,
        value:val
      }
    });
  }

  /**
   * Formats source of funds data for use in the source of funds table
   *
   * @returns array of SourceOfFundsUI objects
   */
  getSourceOfFundsInfoData(): Array<SourceOfFundsUI> {
    const sourceOfFunds = this.contractData?.SourceOfFundsUI || null;
    return sourceOfFunds?.map(data => {
      return {
        ...data,
        PaperworkSentDate: data?.PaperworkSentDate ? this.utilService.getFormattedDate(data?.PaperworkSentDate) : '',
        FundsReceived: data?.FundsReceivedDate ? this.utilService.getFormattedDate(data?.FundsReceivedDate) : ''
      };
    });
  }

  /**
   * Formats source of funds data for use in the funds received table
   *
   * @returns array of FundsReceivedMoneyIn objects
   */
  getFundsReceivedInfoData(): Array<FundsReceivedMoneyIn> {
    const fundsReceived = this.contractData?.FundsReceivedMoneyIn || null;
    return fundsReceived?.map(data => {
      return {
        ...data,
        ClientName: this.utilService.getOwnerName()
      };
    });
  }

  /**
   * Formats requirement data for use in the requirements component
   *
   * @returns array of BRERequirement objects
   */
  getRequirementData(): Array<BRERequirement> {
    const requirements = this.contractData?.BRERequirement || null;
    return requirements?.map(data => {
      return {
        ...data,
        Action: data?.RequirementSeverity === 'INFO' ? 'REVIEW' : 'RESOLVE',
        RequirementDate: data?.RequirementDate ? this.utilService.getFormattedDate(data?.RequirementDate) : '',
        RequirementStatusDate: data?.RequirementStatusDate ? this.utilService.getFormattedDate(data?.RequirementStatusDate) : '',
        Comment: data?.RequirementSeverity === 'INFO' ? '' : 'VIEW COMMENTS'
      };
    });
  }

  /**
   * Checks for 'No LOA' payment type in SourceOfFundsDTCC and SourceOfFundsPpwk data
   */
  checkLOACondition(): void {
    const sourceOfFundsDTCC = this.contractData?.SourceOfFundsDTCC || null;
    const sourceOfFundsPpwk = this.contractData?.SourceOfFundsPpwk || null;
    this.noLOA = (sourceOfFundsDTCC?.filter((data) => { return data.PaymentType === 'No LOA'; }).length > 0) ||
                 (sourceOfFundsPpwk?.filter((data) => { return data.PaymentType === 'No LOA'; }).length > 0);

    this.notNoLOA = (sourceOfFundsDTCC && sourceOfFundsDTCC?.length > 0 ? this.filterObjForNoLOA(sourceOfFundsDTCC): true) &&
                    (sourceOfFundsPpwk && sourceOfFundsPpwk?.length > 0 ? this.filterObjForNoLOA(sourceOfFundsPpwk): true);
  }

  /**
   * Filter Object in SourceOfFundsDTCC and SourceOfFundsPpwk data for not 'No LOA'
   */
  filterObjForNoLOA(obj: any): boolean {
    return obj?.filter((data: any) => { return data.PaymentType !== 'No LOA'; }).length > 0;
  }

  /**
   * set focus on the required element
   */
  setFocus(elementId: string): void {
    setTimeout(() => {
      this.win.document.getElementById(elementId)?.focus();
    }, 200);
  }

  /**
   * build the pending URL and redirect back to respective URL
   */
  backToExistingPendingSearch(): void {
    const siteName = (window as { [key: string]: any })[AppConstants.SITE_NAME] as keyof typeof SECURE_SITES;
    const pendingURL = '/' + AppConstants.SITE_TYPE + SECURE_SITES[siteName] + AppConstants.HUB_PENDING;
    this.win.open(pendingURL, '_self');
    this.analytics.logAnalytics({
      event_action: 'link',
      event_name: 'back to search results',
      event_type: 'pending tool - annuity'
    })
  }
}
