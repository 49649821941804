import config from '../config/envConfig.json';

let envConfig = config.prod;
let env = 'prod';
const windowHostName = `${window.location.hostname}`;

if (windowHostName === 'localhost') {
  envConfig = config.local;
  env = 'local';
} else if (windowHostName === 'annuity-pending-dev.web.lfg.com' || windowHostName === 'lincolnfinancial-test.lfg.com') {
  envConfig = config.dev;
  env = 'test';
} else if (windowHostName === 'annuities-pendingv2-test.web.lfg.com' || windowHostName === 'lincolnfinancial-uat.lfg.com') {
  envConfig = config.test;
  env = 'uat';
} else if (windowHostName === 'lincolnfinancial-preprod.lfg.com') {
  envConfig = config.preprod;
  env = 'preprod';
}

export const environment = {
  production: true,
  env: env,
  uiBaseUrl: envConfig.uiUrl,
  config: {
    isEnabledisplayTxt: false,
  },

  auth: {
    domain: envConfig.auth.domain,
    clientId: envConfig.auth.clientId,
    redirectUri: window.location.href,
    audience: envConfig.auth.audience,
  },

  serviceUrls: {
    apiAuthUrl: envConfig.apiAuthUrl,
    apiUrl: envConfig.apiUrl,
    sfsApiUrl: envConfig.sfsAPIUrl,
    sfsAuthUrl: envConfig.sfsAuthUrl,
    agentProfileMock: '',
    contractDetailsMock: '',
    requirementResolution: '',
    uploadFiles: '',
    downloadFile: 'https://annuity-pending-dev.web.lfg.com/mock/download-file.json',
    getFileDownloadMetadata: '',
  },
};
