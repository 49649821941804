import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, switchMap, tap } from 'rxjs';

import { EntLoaderService } from '@ng/ent-components/ent-loader';

import { AgentComment, BRERequirement, ContractData, NIGOComment, NIGOComments, ResponseStatusInterface } from 'src/app/shared/models/app.model';
import { AgentService } from 'src/app/shared/services/agent.service';
import { AnalyticsAppService } from 'src/app/shared/services/analytics.service';
import { ContractDetailsService } from 'src/app/shared/services/contract-details.service';
import { DownloadFileService } from 'src/app/shared/services/download-file.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { Message, ResponseStatus } from 'src/config/constants';
import { FileMetadataService } from 'src/app/shared/services/file-metadata.service';

@Component({
  selector: 'app-outstanding-requirements',
  templateUrl: './outstanding-requirements.component.html',
  styleUrls: ['./outstanding-requirements.component.scss']
})
export class OutstandingRequirementsComponent implements OnInit {

  contractNumber!: number;
  ownerName: string | undefined;
  outstandingRequirementsFYIInfo = Message.outstandingRequirementsFYInfo;
  contractData!: ContractData;
  BRERequirements!: BRERequirement[];
  NIGOComments!: NIGOComment[];
  AgentComments!: AgentComment[];
  isBRERequirements = false;
  displayFYI = false;
  allRequirementsResolved = false;
  responseStatus: ResponseStatusInterface = ResponseStatus;
  apiStatus$: Observable<string> | undefined;
  message = Message;
  setfocusonHeader = true;
  fileMetadata: any;

  constructor(private contractDetailsService: ContractDetailsService, private utilService: UtilsService, private location: Location,
              private agentService: AgentService, private activatedRoute: ActivatedRoute, private router: Router, private analytics: AnalyticsAppService,
              private downloadFileService: DownloadFileService,  private entLoaderService: EntLoaderService, private fileMetadataService: FileMetadataService ) {}

  ngOnInit(): void {
    window.scroll(0, 0);
    this.contractNumber = Number(this.activatedRoute.snapshot.params['contractNumber']) ?? null;
    this.contractDetailsService.setContractNumber(this.contractNumber);
    this.contractData = this.contractDetailsService.getContractDetails();
    if (this.contractData) {
      if (location.href.includes('#')) {
        this.setFocus(location.href?.split('#')[2] + '-header');
        this.setfocusonHeader = location.href?.split('#')[2] ? false : true;
        this.location.replaceState(location.href?.split('#')[1]);
      }
      this.apiStatus$ = of(this.responseStatus.success);
      this.loadPageData();
    } else {
      this.fetchContractDetails();
    }
    this.fileMetadata = this.fileMetadataService.getFileMetadata();
    if (!this.fileMetadata) {
      this.fetchFileMetadata();
    }
  }

  /**
   * fetch contract details again if data is not available due to page reload
   * @returns {void}
   */
  fetchContractDetails(): void {
    this.apiStatus$ = this.agentService.agentProfileSubject.pipe(
      tap((agentResponse: any) => agentResponse ),
      switchMap((agentResponse: any) => agentResponse ? this.contractDetailsService.fetchContractDetails(agentResponse): ''),
      tap((contractRespone: any) => {
        if (contractRespone !== this.responseStatus.success) {
          return;
        }
        this.contractData = this.contractDetailsService.getContractDetails();
        const clientName = (this.contractData?.ClientFirstName || this.contractData?.ClientLastName)
        ? `${this.contractData?.ClientLastName}, ${this.contractData?.ClientFirstName}` : '';
        this.utilService.setOwnerName(clientName);
        this.utilService.setBRERequirements(this.contractData.BRERequirement);
        this.utilService.setNIGOComments(this.contractData.NIGOComment);
        this.utilService.setAgentComments(this.contractData.AgentComment);
        this.loadPageData();
      }));
    this.agentService.fetchAgentProfile();
  }

  /**
   * Loads data from the utilService service and assigns it to the component properties.
   * @returns {void}
   */
  loadPageData(): void {
    this.contractNumber = this.contractDetailsService.getContractNumber();
    this.ownerName = this.utilService.getOwnerName();
    this.BRERequirements = this.utilService.getBRERequirements()?.filter((data) => { return data.RequirementSeverity === 'NIGO'; });
    this.isBRERequirements = this.BRERequirements?.length > 0;
    this.NIGOComments = this.utilService.getNIGOComments();
    this.AgentComments = this.utilService.getAgentComments();
    this.displayConditionForFYISection();
    this.allOutstandingRequirementsResolved();
    this.getOutstandingCommentsTableData();
    if (this.setfocusonHeader) {
      this.setFocus('pageheader');
    }
    this.analytics.logPageView('outstanding requirements');
  }

  /**
   * Adds tabindex and sets focus dynamically to the header elements for ADA
   * @returns {void}
   */
  setFocus(elementId: string): void {
    setTimeout(() => {
      const element = document.getElementById(elementId);
      if (!element) {
        return;
      }
      element.setAttribute('tabindex', '0');
      element.focus();
      setTimeout(() => {
        element.setAttribute('tabindex', '-1');
      }, 3000);
    }, 200);
  }

  /**
   * Checks display condition for FYI section
   * @returns {void}
   */
  displayConditionForFYISection(): void {
    this.displayFYI = this.contractData?.BRERequirement?.filter((data) => {
      return data.RequirementSeverity === 'INFO' && data.RequirementStatus !== 'Complete';
    }).length > 0;
  }

  /**
   * navigate back to Annuity Pending Details
   * @returns {void}
   */
  backToAnnuityPendingDetails(): void {
    this.router.navigateByUrl(`/contractdetails?contractNumber=${this.contractNumber}`);
    this.analytics.logAnalytics({
      event_action: 'link',
      event_name: 'back to annuity pending details',
      event_type: 'pending tool - annuity'
    })
  }

  /**
   * get data for outstanding requirment comments table from NIGOComment and AgentComment objects
   * @returns {void}
   */
  getOutstandingCommentsTableData(): void {
    this.BRERequirements?.map((BRERdata: BRERequirement, index) => {
      const NIGOandAgentCommentObj = this.getNIGOandAgentComments(BRERdata.ID);
      this.BRERequirements[index].TableData = NIGOandAgentCommentObj;
    });
  }

  /**
   * filter and map NIGOComment and AgentComment based on BRERequirements ID
   * @param BRERequirements ID
   * @returns formatted array
   */
  getNIGOandAgentComments(BRERdataId: string): Array<any> {
    return (this.NIGOComments?.filter((NIGOdata: NIGOComments) => {
      return NIGOdata.ID === BRERdataId;
    })?.map((NIGOval: NIGOComments) => {
      return {
        dateandtime: NIGOval?.NIGOCommentDate ? this.utilService.getFormattedDate(NIGOval.NIGOCommentDate) : '',
        user: 'Lincoln Financial',
        comment: NIGOval.NIGOCommentText,
      }
    }) ?? []).concat(
     this.AgentComments?.filter((agentData: AgentComment) => {
      return  agentData.ID === BRERdataId;
    })?.map((agentVal: AgentComment) => {
      return {
        dateandtime: agentVal?.AgentCommentDate ? this.utilService.getFormattedDate(agentVal.AgentCommentDate) : '',
        user: agentVal.AgentName,
        comment: agentVal.AgentCommentText
      }
    }) ?? []);
  }

  /**
   * Checks all outstanding requirements completed or not and update the header accordingly for Action Needed section
   * @returns {void}
   */
  allOutstandingRequirementsResolved(): void {
    this.allRequirementsResolved = this.contractData?.BRERequirement?.filter((data) => {
      return data.RequirementSeverity === 'NIGO' && data.RequirementStatus !== 'Complete';
    }).length > 0;
  }

  /**
   * Fetches the metadata for files to download
   * @returns {void}
   */
  fetchFileMetadata(): void {
    this.fileMetadataService.fetchFileMetadata().subscribe((res)=>{
      if (res == this.responseStatus.success){
        this.fileMetadata = this.fileMetadataService.getFileMetadata();
      }
    })
  }

}
