import { Injectable, isDevMode } from '@angular/core';
import { EntLoaderService } from '@ng/ent-components/ent-loader';
import { Observable, Subject, of, takeUntil, filter, shareReplay } from 'rxjs';
import { ServiceInvoker } from './service-invoker';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { ResponseStatusInterface } from '../models/app.model';
import { ResponseStatus } from 'src/config/constants';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ContractDetailsService {
  private unSubscribeSub: Subject<void> = new Subject();
  contractDetails: any;
  contractNumber!: number;
  licensingViewLinkClick: Subject<boolean> = new Subject();
  accountlookupFlag: boolean = false;
  paramSub: any;
  userProfile: any;
  responseStatus: ResponseStatusInterface = ResponseStatus;

  constructor(
    private entLoaderService: EntLoaderService,
    private dataService: ServiceInvoker,
    private location: Location,
    private router: Router,
    private http: HttpClient
  ) {}

  fetchContractDetails(response: string): Observable<any> {
    if (response === '500') {
      return of(response);
    }
    if (this.contractDetails) {
      return of(response);
    }
    const subject = new Subject();
    const payload: any = {
      userType: this.getAccountlookupFlag() ? 'CSR' : 'Agent',
      contractNumber: Number(this.contractNumber)
    };
    if (this.getAccountlookupFlag()) {
      payload.isAccountLookUp = 'Y'
    }
    if (environment.env != 'prod') {
       payload.pendingAPI = true;
    }
    this.entLoaderService.show();
    this.dataService
      .getContractDetails(payload)
      .pipe(takeUntil(this.unSubscribeSub))
      .subscribe({
        next: (contractDetails) => {
          if (contractDetails.data) {
            this.entLoaderService.hide();
            this.setContractDetails(contractDetails.data);
            subject.next('success');
            return;
          }
          const error = contractDetails?.error?.errorCode == 401 ? '401' : '500'
          subject.next(error);
          this.entLoaderService.hide();
        },
        error: (resp) => {
          this.entLoaderService.hide();
          const error = resp?.error?.errorCode == 401 ? '401' : '500'
          subject.next(error);
        },
      });

    return subject as Observable<any>;
  }

  setContractDetails(contractDetails: any): void {
    this.contractDetails = contractDetails;
  }

  getContractDetails(): any {
    return this.contractDetails;
  }

  setContractNumber(contractNumber: number) {
    this.contractNumber = contractNumber;
  }

  getContractNumber(): number {
    return this.contractNumber;
  }

  setLicensingViewLinkClick(val: boolean) {
    this.licensingViewLinkClick.next(val);
  }

  getAccountlookupFlag(): boolean {
    return this.accountlookupFlag;
  }

  setAccountlookupFlag(accountlookup: boolean) {
    this.accountlookupFlag = accountlookup;
    this.persistAccountLookupFlag();
  }

  ngOnDestroy(): void {
    this.unSubscribeSub.next();
    this.unSubscribeSub.complete();
  }

  persistAccountLookupFlag() {
    if (this.accountlookupFlag && !this.paramSub) {
      this.paramSub = this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe(()=>{
        const [path,queryparams] = this.router.url.split('?')
        const params = new URLSearchParams(queryparams)
        params.set('accountlookup','true')
        this.location.replaceState(`${path}?${params.toString()}`)
      })
    }
  }
}
