import { Injectable, OnDestroy } from '@angular/core';
import { ServiceInvoker } from './service-invoker';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { AgentProfileInterface } from '../models/app.model';
import { ResponseStatus } from 'src/config/constants';
import { EntLoaderService } from '@ng/ent-components/ent-loader';
import { ContractDetailsService } from './contract-details.service';

@Injectable({
  providedIn: 'root'
})
export class AgentService implements OnDestroy {

  agentProfileSubject: any = new BehaviorSubject('');
  agentProfile: AgentProfileInterface | undefined;
  private unSubscribeSub: Subject<void> = new Subject();

  constructor(private dataService: ServiceInvoker, private entLoaderService: EntLoaderService, private contractDetailsService: ContractDetailsService) { }

  fetchAgentProfile(): any {
    if (this.agentProfile) {
      return;
    }

    const payload = {}
    this.entLoaderService.show();
    this.dataService.getAgentProfile(payload)
    .pipe(takeUntil(this.unSubscribeSub))
    .subscribe({
      next: (agentInfo) => {
        if (agentInfo.data) {
          const isHOuser = sessionStorage.getItem('isHOuser');
          agentInfo.data.isHOuser = isHOuser == 'true' || this.contractDetailsService.getAccountlookupFlag();
          this.setAgentProfile(agentInfo.data);
          this.agentProfileSubject.next(ResponseStatus.success);
          this.entLoaderService.hide();
          return;
        }
        if (agentInfo.error?.errorCode) {
          this.agentProfileSubject.next(ResponseStatus.error500);
          this.entLoaderService.hide();
        }
      },
      error: () => {
        this.agentProfileSubject.next(ResponseStatus.error500);
        this.entLoaderService.hide();
      }
    })
   }

  setAgentProfile(agentProfile: AgentProfileInterface): void {
    this.agentProfile = agentProfile;
  }

  getAgentProfile(): any {
    return this.agentProfile;
  }

  ngOnDestroy(): void {
    this.unSubscribeSub.next();
    this.unSubscribeSub.complete();
  }
}
