import { Component, OnInit } from '@angular/core';

import { EntWindowRefService } from '@ng/ent-utilities';

@Component({
  selector: 'app-scroll-to-top',
  templateUrl: './scroll-to-top.component.html'
})
export class ScrollToTopComponent implements OnInit {

  showTopBtn = false;
  constructor(private winRef: EntWindowRefService) { }

  ngOnInit(): void {
    const self = this;
    this.winRef.getWindow().document.onscroll = () => {
      self.handleScroll();
    };
  }

  /** scroll handling from bottom to top */
  handleScroll(): void {
    this.winRef.getWindow().document.body.scrollTop > 80 ||
    this.winRef.getWindow().document.documentElement.scrollTop > 80 ? this.showBtn() :  this.hideBtn();
  }

  showBtn(): void {
    this.showTopBtn = true;
  }

  hideBtn(): void {
    this.showTopBtn = false;
  }

  /** window scrolling to top of the page */
  gotoTopofthepage(): void {
    this.winRef.getWindow().document.body.scrollTo({ top: 0, behavior: 'smooth' });
    this.winRef.getWindow().document.documentElement.scrollTo({ top: 0, behavior: 'smooth' });
    this.showTopBtn = false;
  }

}
