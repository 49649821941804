import { Injectable } from '@angular/core';
import { HttpEventType } from '@angular/common/http';
import { Observable, Subject, takeUntil } from 'rxjs';
import moment from 'moment';

import { ServiceInvoker } from './service-invoker';
import { SFS_CONFIG } from 'src/config/constants'
import { ContractDetailsService } from './contract-details.service';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class UploadFileService {

  private unSubscribeSub: Subject<void> = new Subject();
  formattedFileNames: any = [];

  constructor(private dataService: ServiceInvoker,
    private contractDetailsService: ContractDetailsService, private utilService: UtilsService
  ) { }

  pwdProtectedFileCheck(files: any): Observable<any> {
    const subject = new Subject();
    const uploadFormData = new FormData();
    uploadFormData.append('files', files);
    uploadFormData.append('ROLE', SFS_CONFIG.ROLE);
    uploadFormData.append('SERVICE_ID', SFS_CONFIG.SFS_PASWRD_PROTECTED_PAYLOAD);
    this.dataService.pwdProtectFile(uploadFormData)
      .pipe(takeUntil(this.unSubscribeSub))
      .subscribe({
        next: (response: any) => {
          if (response.responseStatus === 'SUCCESS') {
            subject.next(response);
            return;
          }
          subject.next(response);
        },
        error: (response) => {
          subject.next(response);
        },
      });
    return subject as Observable<any>;
  }

  uploadFiles(allFiles: any, commentData: any, id?: string): Observable<any> {
    const subject = new Subject();
    const upload = {
      progress: 0,
      status: ''
    };
    const uploadFormData = new FormData();
    allFiles?.forEach((file: any) => {
      uploadFormData.append('files', file, this.getFormattedFileName(file, commentData.date));
    });
    uploadFormData.append('ROLE', SFS_CONFIG.ROLE);
    uploadFormData.append('submissionType', SFS_CONFIG.SUBMISSION_TYPE);
    uploadFormData.append('postToAWD', 'Y');
    uploadFormData.append('commentsSection', '');
    uploadFormData.append('SERVICE_ID', SFS_CONFIG.SFS_UPLOAD_FILE_PAYLOAD);
    this.utilService.setUploadFileNames(this.formattedFileNames);
    this.dataService.uploadFiles(uploadFormData)
      .pipe(takeUntil(this.unSubscribeSub))
        .subscribe({
          next: (response: any) => {
            if (response['type'] === HttpEventType.UploadProgress) {
              console.log('loaded ', response['loaded'], '   total  -', response['total']);
              const progress = Math.round(100 * response.loaded / response.total);
              console.log(progress);
              upload.progress = progress;
              upload.status = '';
              subject.next(upload);
              return;
            }
            if (response?.body?.responseStatus === 'SUCCESS' || response?.responseStatus === 'SUCCESS') {
              upload.status = 'success';
              subject.next(upload);
              this.formattedFileNames = [];
              return;
            }
            if (response?.body && response?.body?.responseStatus !== 'SUCCESS') {
              upload.status = '500';
              this.formattedFileNames = [];
              subject.next(upload);
            }
          },
          error: () => {
            upload.status = '500';
            this.formattedFileNames = [];
            subject.next(upload);
          },
        });
    return subject as Observable<any>;
  }

  getFormattedFileName(file: any, date: any): string {
    const contractNumber = this.contractDetailsService.getContractNumber();
    const formattedDate = moment(date).format('MMDDYYYYhhmmss');
    const extension = file.name?.split('.')?.pop();
    const fileName = file.name?.replace(`.${extension}`, '');
    const updatedName = `${fileName}-${contractNumber}-${formattedDate}.${extension}`;
    this.formattedFileNames.push(updatedName);
    return updatedName;
  }
}
