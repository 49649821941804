<section *ngFor="let BRERequirement of BRERequirements; let i = index" class="requirement-form border-bottom padding-bottom">
  <div class="article-component-cont no-margin no-border" id="form-{{ BRERequirement.ID }}" [ngClass]="{'bg-color': i % 2 == 0}">
    <ent-alert-message type="warning" *ngIf="BRERequirement.textareaControl?.getError('textareaError')">{{ message.addCommentSpeacialChar }}</ent-alert-message>
    <header id="form-{{ BRERequirement.ID }}-header">
      <h4 class="ent-color"><i class="far fa-file-alt" aria-hidden="true"></i>Requirement - </h4>
      <p>{{ BRERequirement.RequirementText }}</p>
    </header>
    <div aria-live="assertive">
      <ent-alert-message type="warning" *ngIf="BRERequirement.MsgState?.isWarning">{{ message.requirementWarningMsg }}</ent-alert-message>
      <ent-alert-message type="success" class="fade-out" *ngIf="BRERequirement.MsgState?.isSuccess">{{ message.requirementSuccessMsg }}</ent-alert-message>
      <ent-alert-message type="error" *ngIf="BRERequirement.MsgState?.isError">{{ message.requirementErrorMsg }}</ent-alert-message>
    </div>
    <form action="javascript:void(0);">
      <div class="form--control">
        <textarea *ngIf="BRERequirement.textareaControl" id="{{BRERequirement.ID}}"
          entValidateOnBlur
          class="form--control--text-area"
          [ngClass]="{'inp-txt--err': BRERequirement?.textareaControl?.touched && BRERequirement.textareaControl.invalid && (BRERequirement.CommentFormError || (BRERequirement.textareaControl.value != '' && BRERequirement.textareaControl.value != null))}"
          [formControl]="BRERequirement.textareaControl"
          (focusout)="resetError(BRERequirement);"
          (keyup)="textareaKeyup(BRERequirement);"
          required maxlength="1000">
        </textarea>
        <label class="inp-float__placeholder"
          [ngClass]="{'inp-float__placeholder--float': BRERequirement.textareaControl?.value != '' && BRERequirement.textareaControl?.value != null }"
          for="{{BRERequirement.ID}}">Enter your comment
        </label>
        <div aria-live="assertive">
          <ent-error *ngIf="BRERequirement.textareaControl?.touched && BRERequirement.textareaControl?.invalid && BRERequirement.CommentFormError && !BRERequirement.textareaControl?.getError('textareaError')"
            message="Please enter your comment.">
          </ent-error>
          <ent-error *ngIf="BRERequirement.textareaControl?.touched && BRERequirement.textareaControl?.invalid && BRERequirement.textareaControl?.value != '' && BRERequirement.textareaControl?.value != null "
            message="Please enter valid comment.">
          </ent-error>
          <span class="screen-reader-only" *ngIf="requirementCompletedFlagADA[i]">requirement completed.</span>
        </div>
      </div>
      <ent-left-button-tray>
        <button type="button" entDefaultButton [disabled]="BRERequirement.RequirementStatus == 'Complete' || isHOUser" (click)="addComment(BRERequirement, i)">
          Add comment
        </button>
        <button type="button" entBorderButton [disabled]="BRERequirement.RequirementStatus == 'Complete' || isHOUser" (click)="triggerUploadFileModal(BRERequirement, i)">
          <i class="fas fa-upload" aria-hidden="true"></i> Upload file
        </button>
      </ent-left-button-tray>
    </form>
  </div>

  <div class="hr-line"></div>

  <ent-primary-table (tableSortTriggered)="tableSortTriggered($event, i)" [tableData]="outstandingCommentsTableCustomData[i]"></ent-primary-table>
  <div class="view-links" aria-live="off">
    <a *ngIf="outstandingCommentsTableCustomData[i].data.length > outstandingCommentsTableCustomData[i].config.rowsPerPage"
    id="viewall" href="javascript:void(0)" (click)="viewAllrows(i)" aria-expanded="false">
    View comments ({{ outstandingCommentsTableCustomData[i].data.length }})
      <i class="fas fa-caret-down" aria-hidden="true"></i>
    </a>
      <a *ngIf="(outstandingCommentsTableCustomData[i].config.rowsPerPage > outstandingCommentsTableRowsPerPage)"
      id="viewless" href="javascript:void(0)" (click)="viewLessrows(i)" aria-expanded="true">View less comments
        <i class="fas fa-caret-up" aria-hidden="true"></i>
    </a>
  </div>
</section>

<app-upload-file *ngIf="uploadBRERequirement?.UploadFile?.ModalState" [index]="index" [BRERequirement]="uploadBRERequirement" [outstandingCommentsTableCustomData]="uploadOutstandingCommentsTableCustomData"></app-upload-file>
