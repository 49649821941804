<section class="article-component-cont">
  <header>
    <h3 class="ent-color"><i class="fas fa-user-clock" aria-hidden="true"></i>Rate lock information</h3>
  </header>
  <div class="article-content rate-lock-info" *ngIf="rateLockInfo">
    <div class="info-row" *ngIf="rateLockInfo?.effectiveDate">
      <p class="info-label">Effective date:</p>
      <p class="info-value">{{ rateLockInfo.effectiveDate }}</p>
    </div>
    <div class="info-row" *ngIf="rateLockInfo?.duration">
      <p class="info-label">Duration:</p>
      <p class="info-value">{{ rateLockInfo.duration }}</p>
    </div>
    <div class="info-row" *ngIf="rateLockInfo?.expirationDate">
      <p class="info-label">Expiration date:</p>
      <p class="info-value">{{ rateLockInfo.expirationDate }}</p>
    </div>
    <div class="info-row" *ngIf="rateLockInfo?.wait30">
      <p class="info-label">Wait 30:</p>
      <p class="info-value">{{ rateLockInfo.wait30 }}</p>
    </div>
  </div>
</section>
