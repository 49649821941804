<ent-modal-popup class="download-file" *ngIf="downloadFileModalConfig" [modalConfig]="downloadFileModalConfig" (closeModalPopup)="closedownloadFileModal()">
  <div modal-header>
    <h3 class="lfg-color">DOWNLOAD FILE(S)</h3>
    <div class="download-file-disc">
      <p>Please select the icon in the download column to access the document(s).</p>
    </div>
  </div>
  <div modal-body>
    <div class="msg-cont" aria-live="assertive">
      <ent-alert-message type="success" *ngIf="isDownloadSuccess" class="fade-out">Selected file(s) are downloaded successfully to your system and a record of this event has been stored in the comments below.</ent-alert-message>
      <ent-alert-message type="error" *ngIf="isDownloadFailure">{{ message.fileUploadFailure }}</ent-alert-message>
      <ent-alert-message type="error" *ngIf="emptyFileListError">{{ message.emptyFileListError }}</ent-alert-message>
    </div>
    <div class="table-cont" role="table">
      <div *ngIf="!emptyFileListError"  role="rowgroup">
        <div role="row" class="file-selected-header top-header">
          <h4 class="file-name-cont file-name no-border-left" role="columnheader">FILE NAME</h4>
          <h4 class="file-name-cont action no-padding-left center-align" role="columnheader">DOWNLOAD</h4>
          <h4 class="file-name-cont action no-padding-left center-align no-border-right line-height" role="columnheader">PREVIOUSLY DOWNLOADED</h4>
        </div>
      </div>

      <ng-container *ngIf="requirementFileMetadata && !emptyFileListError">
        <div *ngFor="let file of requirementFileMetadata; let i = index; let even=even" role="rowgroup">
          <div role="row" class="file-selected-header">
            <div class="file-name-cont file-name" [style.background-color]="even?'#f2f4f6':null" role="cell">{{ file.fileName }}</div>
            <div role="cell" class="file-name-cont action center-align" [style.background-color]="even?'#f2f4f6':null">
              <button type="button" entLinkButton (keydown)="onTab($event, i)"
                (click)="downloadFile(file);" [ngStyle]="{'cursor: default': isDownloadSuccess }" [ngClass]="{'btn--link-disabled': isDownloadSuccess }">
                <i class="fas fa-download" aria-hidden="true"></i>
                <span class="screen-reader-only">download</span>
              </button>
            </div>
            <div class="file-name-cont action center-align previously-downloaded" [style.background-color]="even?'#f2f4f6':null" role="cell">
              <i *ngIf="file.downloaded" class="fas fa-check-circle" aria-hidden="true"></i>
              <span *ngIf="file.downloaded" class="screen-reader-only">check mark</span>
              <span *ngIf="!file.downloaded">N/A</span>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ent-modal-popup>
