import { EntPrimaryTableConfigInterface } from "src/app/shared/models/app.model";

export const RequirementsTableConfig: EntPrimaryTableConfigInterface = {
  config: {
    tableId: 'requirements',
    tableClass: 'outstanding-requirements-table',
    sorting: false,
    defaultSortKey: 'col1',
    pagination: false,
    rowsPerPage: 10,
    tableFoot: false,
    defaultSortOrder: 'desc',
    clickSortOrder: 'asc',
    noRecordsText: 'No search results found.',
    handleSubDetailRow: false,
    currentPage: 0,
    addNewRowLink: false,
    deleteIndRowLink: false,
    handleDelNRowInApp: false,
    handleAddNRowInApp: false,
    isInteractive: true,
    tableCaption: 'requirements table',
    groupByField: '',
    groupByFieldHeader: 'groupFieldHeader'
  },
  column: [
    {
      type: 'string', // string|number|email
      displayText: 'ACTION',
      columnKey: 'Action',
      isAction: 'actionlink',
      pipeName: '',
      align: 'left',
      sorting: true,
    },
    {
      type: 'string', // string|number|email
      displayText: 'REQUIREMENT DATE',
      columnKey: 'RequirementDate',
      isAction: 'noaction',
      pipeName: '',
      sorting: true,
    },
    {
      type: 'string', // string|number|email
      displayText: 'REQUIREMENT',
      columnKey: 'RequirementText',
      isAction: 'noaction',
      pipeName: '',
      align: 'left',
      sorting: true,
    },
    {
      type: 'string', // string|number|email
      displayText: 'STATUS',
      columnKey: 'RequirementStatus',
      isAction: 'noaction',
      pipeName: '',
      align: 'left',
      sorting: true,
      classNameTh: 'align-right'
    },
    {
      type: 'string', // string|number|email
      displayText: 'STATUS DATE',
      columnKey: 'RequirementStatusDate',
      isAction: 'noaction',
      pipeName: '',
      align: 'left',
      sorting: true,
      classNameTh: 'align-right'
    },
    {
      type: 'string', // string|number|email
      displayText: 'COMMENT',
      columnKey: 'Comment',
      isAction: 'actionlink',
      pipeName: '',
      align: 'left',
      sorting: true,
      classNameTh: 'align-right'
    }
  ],
  data: [],
};
