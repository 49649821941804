<ent-modal-popup class="upload-file" *ngIf="uploadFileModalConfig" [modalConfig]="uploadFileModalConfig" (closeModalPopup)="closeUploadFileModal()">
  <div modal-header>
    <h3 class="lfg-color">File upload</h3>
  </div>
  <div modal-body>
    <ent-accordion [autoScroll]="false">
      <ent-panel title="File types" [opened]="true" name="accordion1">
        <ul>
          <li>Lincoln accepts the following file types: PDF, GIF, JPG, JPEG, BMP, TIFF, TIF, DOC, DOCX, XLS and XLSX</li>
          <li>Files should not exceed 30MB</li>
          <li>File names should not include special characters, but may include spaces, underscores (_), hyphens (-) and a period (.)</li>
          <li>Secured or password-protected files will not transmit correctly.</li>
        </ul>
      </ent-panel>
    </ent-accordion>
    <div aria-live="assertive">
      <div *ngIf="displayErrMsg">
        <ent-alert-message type="success" *ngIf="isUploadSuccess" class="fade-out">{{ message.fileUploadSuccess }}</ent-alert-message>
        <ent-alert-message type="error" *ngIf="isUploadFailure">{{ message.fileUploadFailure }}</ent-alert-message>
        <ent-alert-message type="error" *ngIf="isFileTypeError">We're sorry, Your file "{{ fileUploaded }}" {{ message.fileTypeError }}</ent-alert-message>
        <ent-alert-message type="error" *ngIf="isFileNameError">We're sorry, Your file "{{ fileUploaded }}" {{ message.fileNameError }}</ent-alert-message>
        <ent-alert-message type="error" *ngIf="isFileSizeError">{{ message.fileSizeError }}</ent-alert-message>
        <ent-alert-message type="error" *ngIf="isFileSameError">"{{ fileUploaded }}" You have already added this file or a file with the same name.</ent-alert-message>
        <ent-alert-message type="error" *ngIf="isVirusFile">"{{ fileUploaded }}" contains virus or Malware. Please upload healthy files.</ent-alert-message>
        <ent-alert-message type="error" *ngIf="isFileSizeZero">"{{ fileUploaded }}" has size zero. Please upload file with size greater than zero.</ent-alert-message>
        <ent-alert-message type="error" *ngIf="isPassProtectedFile">We're sorry, your file "{{ fileUploaded }}" {{ message.filePassProtectedError }}</ent-alert-message>
      </div>
    </div>
    <div aria-live="assertive">
      <span class="screen-reader-only" *ngIf="removedFileADA">file removed successfully.</span>
      <span class="screen-reader-only" *ngIf="removedAllFilesADA">all files removed successfully.</span>
      <span class="screen-reader-only" *ngIf="fileSelectedADA">file {{fileUploaded}} selected successfully.</span>
    </div>
    <div class="widget-cont" *ngIf="progressValue != 0">
      <div class="widget-header">
        <h3 class="no-margin padding20 lfg-color font-weight200">File upload progress</h3>
      </div>
      <div class="widget-header">
        <div class="widget-content padding20">
          <progress id="progress" class="progress" max="100" [value]="progressValue">70%</progress>
        </div>
      </div>
    </div>
    <div class="add-file">
      <div>
        <input [ngStyle]="{'visibility': 'hidden'}" class="screen-reader-only" #uploadFilesInp type="file" id="uploadfile" title="uploadfile" name="uploadfile" (change)="onFileSelected($event)" />
        <a href="javascript:void(0)" for="uploadfile" tabindex="0" (click)="triggerClick(uploadFilesInp)" (keydown)="triggerClick(uploadFilesInp, $event)" id="uploadfileAnch">
          <i class="fas fa-circle fa-circle-custom" aria-hidden="true">
          <i class="fas fa-plus fa-plus-custom" aria-hidden="true"></i></i> ADD FILE</a>
      </div>
      <div>
        <em class="divider" aria-hidden="true">&#124;</em>
        <button type="button" *ngIf="allFileDetails.length > 0" class="remove-all active" entLinkButton (click)="removeAllFiles();">
          <img src = "{{ environment.uiBaseUrl }}assets/images/delete_all.svg" alt="Remove all files" aria-hidden="true"/>
          <em>Remove all</em>
        </button>
        <div *ngIf="allFileDetails.length == 0" entLinkButton class="btn--link-disabled remove-all" aria-label="Remove all files button unavailable">
          <img src="{{ environment.uiBaseUrl }}assets/images/delete_all-diseble.svg" alt="Remove all files unavailable" aria-hidden="true" />
          <em aria-hidden="true">Remove all</em>
        </div>
      </div>
    </div>

    <div class="file-selected-header">
        <h6 class="file-name">File name</h6>
    </div>
    <div class="file-selected-header" *ngIf="allFileDetails.length == 0">
      <div class="file-name-cont file-name" >No Files have been uploaded yet</div>
      <div entLinkButton class="btn--link-disabled action" aria-label="remove file button unavailable">
        <i class="far fa-trash-alt" aria-hidden="true"></i>
        <span aria-hidden="true">Remove</span>
      </div>
    </div>
    <ng-container *ngIf="allFileDetails.length > 0" aria-live="assertive">
      <div class="file-selected-header" *ngFor="let file of allFileDetails; let i = index">
        <div class="file-name-cont file-name">{{ file.fileName }}</div>
        <button type="button" class="file-name-cont action" (click)="removeFile(i)" entLinkButton [ngClass]="{'btn--link-disabled': isUploadSuccess}">
          <i class="far fa-trash-alt" aria-hidden="true"></i> Remove</button>
    </div>
    </ng-container>
    <p>Closing the window while transmission is in progress will send partial files. Wait for a confirmation message before closing the screen. The message confirms that you have securely submitted your files to Lincoln Financial.</p>
  </div>

  <div modal-footer>
    <ent-left-button-tray>
      <button type="" class='btn btn--custom' entDefaultButton (click)="uploadFiles();" [disabled]="allFileDetails.length == 0">Upload</button>
      <button type="button" class="btn" entLinkButton (click)="closeUploadFileModal()">Cancel</button>
    </ent-left-button-tray>
  </div>
</ent-modal-popup>
