<section class="article-component-cont">
  <header>
    <h3 class="ent-color"><i class="fas fa-file" aria-hidden="true"></i>Source of funds</h3>
  </header>
  <div class="article-content requirement">
    <!-- source of funds table -->
    <ng-content></ng-content>
    <div class="disclaimer">
      <p>
        <strong>* Disclaimer:</strong> {{ disclaimerText.sourceOfFund }}
      </p>
      <p>{{ disclaimerText.sourceOfFundSentDate }}</p>
    </div>
  </div>
</section>
