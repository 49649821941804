<section class="article-component-cont">
  <header>
    <h3 class="ent-color"><i class="fas fa-user-tie" aria-hidden="true"></i>Agent information</h3>
  </header>
  <div class="article-content agent-info">
    <section *ngIf="agentData?.length <= 3">
      <div class="agent-info__grid">
        <ng-container *ngFor="let agent of agentData;">
          <div class="agent-info__row">
            <p><strong>{{ agent.AgentName }}</strong></p>
          </div>
        </ng-container>
      </div>
    </section>
    <!-- agent information table -->
    <ng-content></ng-content>
  </div>
</section>
