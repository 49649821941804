import { Component } from '@angular/core';

import { Disclaimer } from 'src/config/constants'

@Component({
  selector: 'app-source-of-fund',
  templateUrl: './source-of-fund.component.html'
})
export class SourceOfFundComponent {
  disclaimerText = Disclaimer;
}
