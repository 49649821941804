import { EntPrimaryTableConfigInterface } from "src/app/shared/models/app.model";
import { TableConfig } from "src/config/constants";

export const ContractInfoTableConfig: EntPrimaryTableConfigInterface = {
  config: {
    tableId: 'contract-info',
    tableClass: 'contract-info-table',
    tableCaption: 'contract information table',
    ...TableConfig
  },
  column: [
    {
      isAction: 'noaction',
      displayText: 'label',
      columnKey: 'label',
      pipeName: '',
      type: 'string', // string|number|email
      align: 'left',
      sorting: false,
    },
    {
      isAction: 'noaction',
      displayText: 'value',
      columnKey: 'value',
      pipeName: '',
      type: 'innerHTML', // string|number|email
      align: 'left',
      sorting: false,
    },
  ],
  data: [],
};
