import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import moment from 'moment';

import { EntPrimaryTableConfig } from '@ng/ent-components/ent-tables';

import { OutstandingCommentsTableConfig } from '../outstanding-requirement-form/outstanding-requirement-comments-table-config';
import { BRERequirement, ResponseStatusInterface } from 'src/app/shared/models/app.model';
import { Message, OutstandingCommentsTableRowsPerPage, ResponseStatus } from 'src/config/constants';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { AgentService } from 'src/app/shared/services/agent.service';
import { textAreaValidator } from 'src/app/shared/validators/validator';
import { OutstandingRequirementService } from 'src/app/shared/services/outstanding-requirement.service';
import { AnalyticsAppService } from 'src/app/shared/services/analytics.service';
import { FileMetadataService } from 'src/app/shared/services/file-metadata.service';


@Component({
  selector: 'app-outstanding-requirement-form',
  templateUrl: './outstanding-requirement-form.component.html',
  styleUrls: ['./outstanding-requirement-form.component.scss']
})
export class OutstandingRequirementFormComponent implements OnInit {

  @Input() BRERequirements!: BRERequirement[];
  @Input() outstandingCommentsTableConfig: EntPrimaryTableConfig = OutstandingCommentsTableConfig;
  responseStatus: ResponseStatusInterface = ResponseStatus;
  outstandingCommentsTableCustomData: Array<any> = [];
  outstandingCommentsTableRowsPerPage: number = OutstandingCommentsTableRowsPerPage;
  message = Message;
  requirementSectionIndex: number = 0;
  confirmCompletionModalConfig = {
    header: true,
    state: false,
    footer: true,
    size: 'medium',
  };
  tableSortSelected: any = {};
  commentAddedFlagADA: any = {};
  requirementCompletedFlagADA: any = {};
  isHOUser = false;
  win: any = '';
  uploadBRERequirement!: BRERequirement;
  downloadBRERequirement!: BRERequirement;
  uploadOutstandingCommentsTableCustomData!: EntPrimaryTableConfig;
  downloadOutstandingCommentsTableCustomData!: EntPrimaryTableConfig;
  index = 0;

  constructor(private utilService: UtilsService, private agentService: AgentService,
              private outstandingReqService: OutstandingRequirementService,
              private analytics: AnalyticsAppService, private fileMetadataService: FileMetadataService) {}

  ngOnInit(): void {
    this.isHOUser = this.agentService.getAgentProfile()?.isHOuser || false;
    this.BRERequirements?.sort((a, b) => a.RequirementStatus > b.RequirementStatus ? -1 : 1);
    this.BRERequirements?.map((data: any, index) => {
      this.outstandingCommentsTableConfig.data = data.TableData;
      this.outstandingCommentsTableConfig.config = {...this.outstandingCommentsTableConfig.config};
      this.outstandingCommentsTableConfig.config.tableId = `outstanding-comments-${index}`;
      this.outstandingCommentsTableConfig.config.tableClass = `outstanding-comments-table-${index}`;
      this.outstandingCommentsTableCustomData.push({...this.outstandingCommentsTableConfig});
      data.textareaControl = new FormControl({value: '', disabled: data.RequirementStatus === 'Complete' || this.isHOUser}, [textAreaValidator()]);
    });
  }

  /**
   * Sets outstanding Comments table config to display all rows
   *
   * @param index of BRERequirements object
   */
  viewAllrows(index: number): void {
    this.outstandingCommentsTableConfig.config.rowsPerPage = this.outstandingCommentsTableCustomData[index].data.length;
    const tableID = this.outstandingCommentsTableCustomData[index].config.tableId;
    const tableClass = this.outstandingCommentsTableCustomData[index].config.tableClass;
    this.outstandingCommentsTableCustomData[index].config = {...this.outstandingCommentsTableConfig.config}
    this.outstandingCommentsTableCustomData[index].config.tableId = tableID;
    this.outstandingCommentsTableCustomData[index].config.tableClass = tableClass;
    this.outstandingCommentsTableConfig.config.rowsPerPage = this.outstandingCommentsTableRowsPerPage;
    if (this.tableSortSelected[index]) {
      this.outstandingCommentsTableCustomData[index].config.defaultSortOrder = 'asc';
    }
    this.utilService.setFocusOnTable(`outstanding-comments-${index}`);
  }

  /**
   * Sets outstanding Comments table config to display 3 rows
   *
   * @param index of BRERequirements object
   */
  viewLessrows(index: number): void {
    this.outstandingCommentsTableCustomData[index].config.rowsPerPage = this.outstandingCommentsTableRowsPerPage;
    this.utilService.setFocusOnTable(`outstanding-comments-${index}`);
  }

  /**
   * get sorting order when click on th of datetime in comments table
   *
   * @param e event of clicked th
   * @param i index of BRERequirement object
   */
  tableSortTriggered(e: any, i: number): void {
    this.tableSortSelected[i] = e.order;
  }

  /**
   * reset comment from error when focus out of the textarea
   *
   * @param BRERequirement object
   */
  resetError(BRERequirement: BRERequirement): void {
    BRERequirement.CommentFormError = false;
  }

  /**
   * store added comment to BRERequirement table object and AgentComments object
   *
   * @param BRERequirement object
   * @param i index of BRERequirement
   */
  addComment(BRERequirement: BRERequirement, i: number): void {
    if (BRERequirement.textareaControl?.value == '' || BRERequirement.textareaControl?.value == null || BRERequirement.textareaControl?.getError('textareaError')) {
      BRERequirement.CommentFormError = true;
      BRERequirement.textareaControl?.markAsTouched();
      return;
    }
    const commentData = {
      date:  moment(new Date()).format('MM/DD/YYYY hh:mm A'),
      userName: `${this.agentService.getAgentProfile()?.lastName?.trim()}, ${this.agentService.getAgentProfile()?.firstName?.trim()}`,
      comment: BRERequirement.textareaControl?.value?.replace(/\n/g, ' ')
    }
    BRERequirement.MsgState = {
      isError: false
    }
    this.outstandingReqService.requirementResolution('Agent', commentData, BRERequirement.ID)
      .subscribe((resp: any) => {
        if (resp === this.responseStatus.success) {
          this.addCommentsToTable(BRERequirement, commentData, i);
        } else {
          BRERequirement.MsgState = {
            isError: true
          }
        }
    });

    this.analytics.logAnalytics({
      event_action: 'button',
      event_name: 'add comment',
      event_type: 'pending tool - annuity',
      event_version: BRERequirement.RequirementText
    })
  }

  /**
   * store added comment to BRERequirement table object and AgentComments object
   *
   * @param BRERequirement object
   * @param commentData object
   * @param i index of BRERequirement
   */
  addCommentsToTable(BRERequirement: any, commentData: any, i: number): void {
    BRERequirement.TableData?.unshift({
      dateandtime: commentData.date,
      user: commentData.userName,
      comment: commentData.comment
    });
    this.utilService.getAgentComments().push({
      ID: BRERequirement.ID,
      AgentCommentText: commentData.comment,
      AgentCommentDate: commentData.date,
      AgentName: commentData.userName,
    });
    this.setMsgFlags(BRERequirement);
    this.viewLessrows(i);
    this.setADAFlag(i);
  }

  /**
   * set flags for success, warning
   *
   * @param BRERequirement object
   */
  setMsgFlags(BRERequirement: any): void {
    BRERequirement.MsgState = {
      isWarning: false
    }
    BRERequirement.MsgState = {
      isSuccess: true
    }
    setTimeout(() => {
      BRERequirement.MsgState = {
        ...BRERequirement.MsgState,
        isSuccess: false
      }
    }, 5000);
    BRERequirement.textareaControl?.reset();
  }

  setADAFlag(i: number): void {
    this.commentAddedFlagADA[i] = true;
    setTimeout(() => {
      this.commentAddedFlagADA[i] = false;
    }, 2000);
  }

  /**
   * reset warning msg when there is no value present in textarea
   *
   * @param BRERequirement Object
   */
  textareaKeyup(BRERequirement: BRERequirement): void {
    if (!BRERequirement.textareaControl?.value) {
      BRERequirement.MsgState = {
        isWarning: false
      }
    }
  }

  /**
   * open file upload modal and set input for file upload component
   *
   * @param BRERequirement object
   * @param i index of BRERequirement
   */
  triggerUploadFileModal(BRERequirement: BRERequirement, i: number): void {
    BRERequirement.UploadFile = {
      ModalState: true,
    }
    this.index = i;
    this.uploadBRERequirement = BRERequirement;
    this.uploadOutstandingCommentsTableCustomData = this.outstandingCommentsTableCustomData[i];

    this.analytics.logAnalytics({
      event_action: 'button',
      event_name: 'upload file',
      event_type: 'pending tool - annuity',
      event_version: BRERequirement.RequirementText
    })
  }

  triggerDownloadFileModal(BRERequirement: BRERequirement, i: number): void {
    BRERequirement.DownloadFile = {
      ModalState: true,
    }
    this.downloadBRERequirement = BRERequirement;
    this.downloadOutstandingCommentsTableCustomData = this.outstandingCommentsTableCustomData[i];
  }

  getFileCount(BRERequirement: any) {
    const fileMetadata = this.fileMetadataService.getFileMetadata()
    const requirementFileMetadata = fileMetadata?.requirements?.find((file:any) => {
      return file.requirementId === BRERequirement.ID;
    })?.files;
    return requirementFileMetadata ? requirementFileMetadata?.length == 0 : true;
  }
}
