import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment as env } from '../environments/environment';

import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { EntClipboardService, EntRestClientService } from '@ng/ent-utilities';

import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { AnalyticsAppService } from './shared/services/analytics.service';


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    AuthModule.forRoot({
      ...env.auth,
      httpInterceptor: {
        allowedList: [
          {
            uri: env.serviceUrls.apiAuthUrl,
            tokenOptions: {
              authorizationParams: {
                audience: env.auth.audience,
                scope: 'profile openid',
              }
            },
          },
          {
            uri:  env.serviceUrls.sfsAuthUrl,
            tokenOptions: {
              authorizationParams: {
                audience: env.auth.audience,
                scope: 'profile openid',
              }
            },
          },
        ],
      }
    })
  ],
  providers: [
    EntRestClientService,
    AnalyticsAppService,
    EntClipboardService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
