import { Injectable } from '@angular/core';
import { CustomDatePipe, NumberFormatPipe } from '../pipes/app.pipes';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  ownerName: string | undefined;
  BRERequirements!: Object[];
  AgentComments!: Object[];
  NIGOComments!: Object[];
  outstandingRequirementStatus!: Object[];
  uploadFileNames!: Array<any>[];
  filesToDownload: any;

  getFormattedDate(value: string): any {
    const dateVal = value?.split(' ');
    const customPipe = new CustomDatePipe();
    const date = customPipe.transform(dateVal[0], 'MM/DD/YYYY');
   return `${date} ${dateVal[1] ?? ''} ${dateVal[2] ?? ''}`.trim();
  }

  getFormattedPhoneNumber(value: string): any {
    const customPipe = new NumberFormatPipe();
    return customPipe.transform(value);
  }

  getFormattedName(name: string): string {
    if ((name?.split(',')[0]?.trim()?.length <= 0) || (name?.split(',')[1]?.trim()?.length <= 0)) {
      return name?.replace(',', '')?.trim();
    }
    return name;
  }

  setOwnerName(name: string) {
    // format name
    name = this.getFormattedName(name);
    this.ownerName = name;
  }


  getOwnerName(): string {
    return this.ownerName ?? '';
  }

  setBRERequirements(requirements: Array<any>): void {
    this.BRERequirements = requirements;
  }

  getBRERequirements(): Array<any> {
    return this.BRERequirements;
  }

  setNIGOComments(NIGOComments: Array<any>): void {
    this.NIGOComments = NIGOComments;
  }

  getNIGOComments(): Array<any> {
    return this.NIGOComments;
  }

  setAgentComments(agentComments: Array<any>): void {
    this.AgentComments = agentComments;
  }

  getAgentComments(): Array<any> {
    return this.AgentComments;
  }

  setRequirementStatus(status: Array<any>): void {
    this.outstandingRequirementStatus = status;
  }

  getRequirementStatus(): Array<any> {
    return this.outstandingRequirementStatus;
  }

  public toBase64(file: File): Promise<any> {
    const reader: any = new FileReader();
    reader.readAsDataURL(file);
    return new Promise<any>((resolve) => {
      reader.onload = () => {
        let generated64 = reader.result.toString();
        generated64 = generated64.split(',').pop();
        resolve(generated64);
      };
    });
  }

  setUploadFileNames(fileNames: Array<any>): void {
    this.uploadFileNames = fileNames;
  }

  getUploadFileNames(): Array<any> {
    return this.uploadFileNames;
  }

  setFocusOnTable(elementId: string): void {
    const element = document.getElementById(elementId);
    if (!element) {
      return;
    }
    const ele1: HTMLElement | null = element?.querySelector('.ent-table-primary__th-sort-btn');
    setTimeout(() => {
      if (ele1) {
        ele1.focus();
      }
    }, 200);
  }

  setFocus(elemId: string): void {
    setTimeout(() => {
      const ele = document.getElementById(elemId);
      if (ele) {
        ele.focus();
      }
    }, 200);
  }

  addTrademarkSymbol(text: string): any {
    if (text?.indexOf('American Legacy') === -1) {
      text = this.getItalicizedText(text, '(R)');
      text = this.getItalicizedText(text, '(SM)');
      text = this.getItalicizedText(text, '(TM)');
    }
    return text?.replace('(R)', '\<sup>&#174;\</sup>')?.replace('(SM)', '\<sup class=\'sup-sm\'>SM\</sup>')?.replace('(TM)', '\<sup class=\'sup-sm\'>TM\</sup>');
  }

  getItalicizedText(text: any, trademark: any): string {
    const tempText = text?.split(trademark);
    if (tempText?.length > 1) {
      return `<em>${tempText[0]}</em>${trademark}${tempText[1]}`;
    }
    return text;
  }

  setFilesToDownload(filesToDownload: any) {
    this.filesToDownload = filesToDownload;
  }

  getFilesToDownload() {
    return this.filesToDownload;
  }
}
