import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-agent-information',
  templateUrl: './agent-information.component.html'
})
export class AgentInformationComponent {

  @Input() agentData: any;

}
