import { Injectable, OnDestroy, isDevMode } from '@angular/core';
import { ServiceInvoker } from './service-invoker';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { AgentProfileInterface } from '../models/app.model';
import { ResponseStatus } from 'src/config/constants';
import { EntLoaderService } from '@ng/ent-components/ent-loader';
import { ContractDetailsService } from './contract-details.service';
import { profile } from '../../../mock/userProfile'

@Injectable({
  providedIn: 'root'
})
export class AgentService implements OnDestroy {

  agentProfileSubject: any = new BehaviorSubject('');
  agentProfile: AgentProfileInterface | undefined;
  private unSubscribeSub: Subject<void> = new Subject();
  userProfile: any;

  constructor(private dataService: ServiceInvoker, private entLoaderService: EntLoaderService, private contractDetailsService: ContractDetailsService) { }

  fetchAgentProfile(): void {
    const isHOuser = sessionStorage.getItem('isHOuser') === 'true' || this.contractDetailsService.getAccountlookupFlag();
    if (this.agentProfile) {
      this.agentProfileSubject.next(ResponseStatus.success);
      return;
    }
    if (isHOuser || this.getUserProfile()) {
      this.agentProfile = this.createAgentProfile(isHOuser);
      this.agentProfileSubject.next(ResponseStatus.success);
      return;
    }
    this.loadAgentProfile(isHOuser);
  }
  
  private createAgentProfile(isHOuser: boolean): any {
    return {
      firstName: this.userProfile?.FIRST_NAME || '',
      lastName: this.userProfile?.LAST_NAME || '',
      middleName: '',
      email: '',
      allStateFlag: false,
      isHOuser: isHOuser,
      proxyGuid: this.userProfile?.PROXY_GUID || '',
      hubRole: this.userProfile?.HUB_ROLE || '',
      role: this.userProfile?.ROLE || ''
    };
  }
  
  private loadAgentProfile(isHOuser: boolean): void {
    const payload = {};
    this.entLoaderService.show();
  
    this.dataService.getAgentProfile(payload)
      .pipe(takeUntil(this.unSubscribeSub))
      .subscribe({
        next: (agentInfo) => {
          if (agentInfo.data) {
            agentInfo.data.isHOuser = isHOuser;
            this.setAgentProfile(agentInfo.data);
            this.agentProfileSubject.next(ResponseStatus.success);
          } else if (agentInfo.error?.errorCode) {
            this.agentProfileSubject.next(ResponseStatus.error500);
          }
          this.entLoaderService.hide();
        },
        error: () => {
          this.agentProfileSubject.next(ResponseStatus.error500);
          this.entLoaderService.hide();
        }
      });
  }

  setAgentProfile(agentProfile: AgentProfileInterface): void {
    this.agentProfile = agentProfile;
  }

  getAgentProfile(): any {
    return this.agentProfile;
  }

  ngOnDestroy(): void {
    this.unSubscribeSub.next();
    this.unSubscribeSub.complete();
  }

  getUserProfile() {
    if (this.agentProfile) {
      return this.agentProfile;
    }
    if (isDevMode()) {
      sessionStorage.setItem('userProfileData',JSON.stringify(profile)) ;
    }
    const data = sessionStorage.getItem('userProfileData');
    if (data) {
      this.userProfile = JSON.parse(data);
    }
    return this.userProfile;
  }
}
