import { EntPrimaryTableConfigInterface } from "src/app/shared/models/app.model";
import { TableConfig } from "src/config/constants";

export const SourceOfFundsTableConfig: EntPrimaryTableConfigInterface = {
  config: {
    tableId: 'source-of-funds',
    tableClass: 'source-of-funds-table',
    tableCaption: 'source of funds table',
    ...TableConfig
  },
  column: [
    {
      align: 'left',
      displayText: 'CLIENT NAME',
      columnKey: 'ClientName',
      isAction: 'noaction',
      pipeName: '',
      type: 'string', // string|number|email
      sorting: true,
    },
    {
      align: 'left',
      displayText: 'SOURCE OF FUNDS',
      columnKey: 'SourceOfFunds',
      isAction: 'noaction',
      pipeName: '',
      type: 'string', // string|number|email
      sorting: true,
    },
    {
      align: 'left',
      displayText: 'PAPERWORK SENT',
      columnKey: 'PaperworkSentDate',
      isAction: 'noaction',
      pipeName: '',
      type: 'string', // string|number|emai
      sorting: true,
    },
    {
      isAction: 'noaction',
      classNameTh: 'align-right',
      pipeName: 'currency',
      align: 'right',
      displayText: 'EXPECTED AMOUNT',
      columnKey: 'ExpectedAmt',
      type: 'string', // string|number|emai
      sorting: true
    },
    {
      align: 'left',
      displayText: 'STATUS',
      columnKey: 'TransferStatus',
      isAction: 'noaction',
      sorting: true,
      classNameTh: 'align-right',
      pipeName: '',
      type: 'string', // string|number|email
    },
    {
      align: 'left',
      displayText: 'CARRIER CALL',
      columnKey: 'CarrierCall',
      isAction: 'noaction',
      sorting: true,
      classNameTh: 'align-right',
      pipeName: '',
      type: 'string', // string|number|email,
    },
    {
      align: 'left',
      displayText: 'FUNDS RECEIVED',
      columnKey: 'FundsReceived',
      classNameTh: 'align-right',
      isAction: 'noaction',
      pipeName: '',
      type: 'string', // string|number|email
      sorting: true
    },
    {
      classNameTh: 'align-right',
      pipeName: '',
      type: 'string', // string|number|email
      align: 'left',
      displayText: 'COMMENTS',
      columnKey: 'Comments',
      isAction: 'noaction',
      sorting: true
    }
  ],
  data: [],
};
