import { Component, Input } from '@angular/core';

import { RateLockInfoData } from 'src/app/shared/models/app.model';

@Component({
  selector: 'app-rate-lock-information',
  templateUrl: './rate-lock-information.component.html',
  styleUrls: ['./rate-lock-information.component.scss']
})
export class RateLockInformationComponent {
  @Input()
  rateLockInfo: RateLockInfoData | undefined;
}
