import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

moment.suppressDeprecationWarnings = true;

@Pipe({
  name: 'customDate'
})
export class CustomDatePipe implements PipeTransform {

  transform(value: any, format: string): string {
    value = value?.split(' ')[0];
    const momentDate = moment(value);
    if (!momentDate.isValid()) {
      return value;
    }
    return momentDate.format(format);
  }

}

@Pipe({
  name: 'phoneNumber',
})
export class NumberFormatPipe implements PipeTransform {

  transform(phone: string): string {
    if (!phone) {
      return '';
    }
    if (phone.trim().length === 10) {
      return '(' +
        phone.substring(0, 3) +
        ') ' +
        phone.substring(3, 6) +
        '-' +
        phone.substring(6);
    }
    return phone;
  }

}
