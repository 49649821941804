import { EntPrimaryTableConfigInterface } from "src/app/shared/models/app.model";
import { TableConfig } from "src/config/constants";

export const OutstandingCommentsTableConfig: EntPrimaryTableConfigInterface = {
    config: {
      tableId: 'outstanding-comments',
      tableClass: 'outstanding-comments-table',
      tableCaption: 'outstanding-comments table',
      ...TableConfig,
      rowsPerPage: 3,
      pagination: true,
      sorting: true,
      defaultSortKey: 'dateandtime',
      noRecordsText: 'No comments to display'
    },
    column: [
      {
        displayText: 'DATE/TIME',
        columnKey: 'dateandtime',
        isAction: 'noaction',
        pipeName: '',
        type: 'date', // string|number|email
        align: 'left',
        sorting: true,
      },
      {
        displayText: 'USER',
        columnKey: 'user',
        isAction: 'noaction',
        pipeName: '',
        type: 'innerHTML', // string|number|email
        align: 'left',
        sorting: false,
      },
      {
        displayText: 'COMMENT',
        columnKey: 'comment',
        isAction: 'noaction',
        pipeName: '',
        type: 'innerHTML', // string|number|email
        align: 'left',
        sorting: false,
      }
    ],
    data: [],
  };
