import { Component, Input } from '@angular/core';
import moment from 'moment';

import { EntPrimaryTableConfig } from '@ng/ent-components/ent-tables';
import { EntLoaderService } from '@ng/ent-components/ent-loader';

import { BRERequirement, FileDetails } from 'src/app/shared/models/app.model';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { OutstandingCommentsTableConfig } from '../outstanding-requirement-form/outstanding-requirement-comments-table-config';
import { Message, OutstandingCommentsTableRowsPerPage, SFS_CONFIG, fileTypes } from 'src/config/constants';

import { AgentService } from 'src/app/shared/services/agent.service';
import { UploadFileService } from 'src/app/shared/services/upload-file.service';

import { environment } from 'src/environments/environment.prod'
import { AnalyticsAppService } from 'src/app/shared/services/analytics.service';
import { OutstandingRequirementService } from 'src/app/shared/services/outstanding-requirement.service';
import { EntWindowRefService } from '@ng/ent-utilities';


@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent {

  @Input() index = 0;
  @Input() BRERequirement!: BRERequirement;
  @Input() outstandingCommentsTableCustomData!: EntPrimaryTableConfig;
  outstandingCommentsTableConfig: EntPrimaryTableConfig = OutstandingCommentsTableConfig;
  outstandingCommentsTableRowsPerPage: number = OutstandingCommentsTableRowsPerPage;
  fileTypes = fileTypes;
  uploadFileModalConfig = {
    header: true,
    state: true,
    footer: true
  }
  displayErrMsg!: boolean;
  isUploadSuccess!: boolean;
  isUploadFailure!: boolean;
  isFileTypeError!: boolean;
  isFileSizeError!: boolean;
  isFileSameError!: boolean;
  isFileNameError!: boolean;
  isFileSizeZero!: boolean;
  isVirusFile!: boolean;
  isPassProtectedFile = false;
  fileUploaded!: string;
  allFileDetails: any[] = [];
  allFiles: any[] = [];
  progressValue = 0;
  progressStatus = '';
  environment: any = environment;
  message = Message;
  removedFileADA = false;
  removedAllFilesADA = false;
  fileSelectedADA = false;
  uploadFileInput: any;

  constructor(private utilsService: UtilsService, private agentService: AgentService,
    private uploadFileService: UploadFileService, private entLoaderService: EntLoaderService,
    private analytics: AnalyticsAppService, private outstandingReqService: OutstandingRequirementService,
    private winRef: EntWindowRefService) {}

  /**
   * close upload Modal
   */
  closeUploadFileModal(): void {
    // set focus on next focusable element
    setTimeout(() => {
      const tableID = `.outstanding-comments-table-${this.index}`
      const nextEle = document.querySelector(`${tableID} .ent-table-primary__th-sort-btn`) as HTMLElement;
      if (nextEle) {
        nextEle?.focus();
      }
      this.uploadFileModalConfig.state = false;
      this.BRERequirement.UploadFile = {
        ModalState: false,
      }
    }, 300);

  }

  /**
   * on file select check for file validation
   *
   * @param event of file selected
   */
  async onFileSelected(event: any): Promise<void> {
    this.resetProps();
    const file: File = event.target.files[0];
    this.fileUploaded = file.name;
    event.target.value = null;
    if (file) {
      if (file?.size === 0) {
        this.isFileSizeZero = true;
        this.delayDisplayErrorSection();
        return;
      }
      if (!this.validFileType(file)) {
        this.isFileTypeError = true;
        this.delayDisplayErrorSection();
        return;
      }
      const testValidFile = file.name.replace(/[A-Za-z0-9._\- ]*/g, '');
      if (testValidFile.length > 0) {
        this.isFileNameError = true;
        this.delayDisplayErrorSection();
        return;
      }
      if (file?.size > 30 * 1024 * 1024) {
        this.isFileSizeError = true;
        this.delayDisplayErrorSection();
        return;
      }
      if (this.checkForSameFileName(file)) {
        this.isFileSameError = true;
        this.delayDisplayErrorSection();
        return;
      }
      this.checkForPwdProctectedFile(file);
      this.delayDisplayErrorSection();
    }
  }

  /**
   * get total file size of selected files
   *
   * @returns size of selected files
   */
  getTotalFileSize(): number {
    let size: number = 0
    this.allFileDetails.forEach((element: FileDetails) => {
      size += Number(element.fileSize);
    });
    return size;
  }

  /**
   * check file is already uploaded
   *
   * @param selected file
   *
   * @returns true if already uploaded
   */
  checkForSameFileName(file: File): boolean {
    return this.allFileDetails.some((element: FileDetails) => {
      return file.name === element.fileName });
  }

  /**
   * get all files details before upload
   *
   * @param file
   */
  private collectfileDetails(file: File): void {
    this.utilsService.toBase64(file).then((pdfData: any) => {
      const fileDetails: FileDetails = {
        fileName: file.name,
        fileSize: file.size.toString(),
        fileType: file.type,
        fileData: pdfData
      };
      this.allFileDetails.push(fileDetails);
    })
    this.allFiles.push(file);
  }

  /**
   * verify user selected file for pwd protection, malicious file
   */
  checkForPwdProctectedFile(file: File): void {
    this.resetProps();
    this.entLoaderService.show();
    this.uploadFileService.pwdProtectedFileCheck(file).subscribe((res: any) => {
      if (res.responseStatus === 'SUCCESS' && res.data.length > 0) {
        this.isPassProtectedFile = true;
        this.entLoaderService.hide();
        this.delayDisplayErrorSection();
        return;
      } else if (res.responseStatus === 'SUCCESS') {
        this.isPassProtectedFile = false;
        this.collectfileDetails(file);
        if (this.getTotalFileSize() > 30 * 1024 * 1024) {
          this.allFileDetails.splice(this.allFileDetails?.length, 1);
          this.allFiles.splice(this.allFiles?.length, 1);
          this.isFileSizeError = true;
          this.delayDisplayErrorSection();
          this.entLoaderService.hide();
          return;
        }
        this.entLoaderService.hide();
        this.checkFileSelectedFlagForADA();
        return;
      }
      if (res.responseStatus === 'FAILURE') {
        if (res.error === SFS_CONFIG.VIRUS_SCAN_FILE_ERROR_KEY) {
          this.allFiles.splice(this.allFiles?.length, 1);
          this.isVirusFile = true;
          this.delayDisplayErrorSection();
          this.entLoaderService.hide();
          return;
        }
        this.isUploadFailure = true;
        this.delayDisplayErrorSection();
        this.entLoaderService.hide();
        return;
      }
      this.isUploadFailure = true;
      this.delayDisplayErrorSection();
      this.entLoaderService.hide();
    });
  }

  /**
   * set flag for ADA message on successful file selection from browser
   */
  checkFileSelectedFlagForADA(): void {
    setTimeout(() => {
      this.fileSelectedADA = this.checkFileSelected();
    }, 1000);
    setTimeout(() => {
      this.fileSelectedADA = false;
    }, 2000);
  }

  /**
   * upload files and add details to requriment table
   */
  uploadFiles(): void {
    this.resetProps();
    let fileNames: any = [];
    this.allFileDetails?.forEach((details: FileDetails) => {
      fileNames.push(details.fileName);
    });
    fileNames = fileNames.join(', ');
    const commentData = {
      date:  new Date(),
      userName: `${this.agentService.getAgentProfile()?.lastName?.trim()}, ${this.agentService.getAgentProfile()?.firstName?.trim()}`,
      comment: `File(s) uploaded with the file(s) name (${fileNames})`
    }
    this.uploadFileService.uploadFiles(this.allFiles, commentData, this.BRERequirement?.ID).subscribe((res: any) => {
      this.progressValue = res.progress;
      if (res.status == 'success') {
        this.progressStatus = res.status;
        this.progressValue = 0;
        this.callAnnuityAPI(this.BRERequirement?.ID || '', commentData);
        this.delayDisplayErrorSection();
        return;
      }
      if (res.status == '500') {
        this.progressValue = 0;
        this.isUploadFailure = true;
      }
      this.delayDisplayErrorSection();
    })
  }

  callAnnuityAPI(id: string, commentData: any): void {
    this.outstandingReqService.requirementResolution('Attachment', commentData, id || '')
      .subscribe((resp: any) => {
        if (resp === 'success') {
          this.allFiles = [];
          this.isUploadSuccess = true;
          this.delayDisplayErrorSection();
          this.BRERequirement.TableData?.unshift({
            dateandtime: moment(commentData.date).format('MM/DD/YYYY hh:mm A'),
            user: commentData.userName,
            comment: commentData.comment
          })
          this.outstandingCommentsTableCustomData.config.rowsPerPage = this.outstandingCommentsTableRowsPerPage;
          this.setAgentComments(commentData);
          this.allFileDetails = [];
          this.analytics.logAnalytics({
            event_action: 'button',
            event_name: 'submit file',
            event_type: 'pending tool - annuity',
            event_version: this.BRERequirement.RequirementText
          })
          setTimeout(() => {
            this.progressStatus = '';
            this.isUploadSuccess = false;
          }, 5000)
          return;
        }
        if (resp == '500') {
          this.progressStatus = '';
          this.isUploadFailure = true;
          this.delayDisplayErrorSection();
          return;
        }
    });
  }

  /**
   * Add file details to Agent comments object
   *
   * @param contracted comment Data
   */
  setAgentComments(commentData: any): any {
    this.allFileDetails.forEach((details: FileDetails) => {
      this.utilsService.getAgentComments().push({
        ID: this.BRERequirement.ID,
        AgentCommentText: `File uploaded with the file name [${details.fileName}]`,
        AgentCommentDate: moment(commentData.date).format('MM/DD/YYYY hh:mm A'),
        AgentName: commentData.userName
      })
    })
  }

  /**
   * remove specific file
   *
   * @param i index of selected file
   */
  removeFile(i: number): void {
    this.allFileDetails.splice(i, 1);
    this.allFiles.splice(i, 1);
    this.removedFileADA = true;
    setTimeout(() => {
      this.removedFileADA = false;
    }, 2000);
    this.utilsService.setFocus('uploadfileAnch')
  }

  /**
   * validate selected file type
   *
   * @param fileType slected file type
   *
   * @returns true if matches file type
   */
  validFileType(file: File) {
    return this.fileTypes.includes(file.type) &&  file.name.split('.')[1] != 'rtf' && file.name.split('.')[1] != 'jpe';
  }

  /**
   * remove all selected files
   */
  removeAllFiles(): void {
    this.resetProps();
    this.allFileDetails = [];
    this.allFiles = [];
    this.removedAllFilesADA = true;
    setTimeout(() => {
      this.removedAllFilesADA = false;
    }, 2000);
    this.utilsService.setFocus('uploadfileAnch');
  }

  /**
   * reset properties
   */
  resetProps(): void {
    this.isUploadSuccess = false;
    this.isUploadFailure = false;
    this.isFileTypeError = false;
    this.isFileSizeError = false;
    this.isFileSameError = false;
    this.isFileNameError = false;
    this.isFileSizeZero = false;
    this.isVirusFile = false;
    this.isPassProtectedFile = false;
  }

   /**
   * add delay for success/warning/error messages for ADA
   */
  delayDisplayErrorSection(): void {
    // ADA fix for reading messages on file upload modal
    this.displayErrMsg = false;
    if (this.isUploadSuccess || this.isUploadFailure || this.isFileTypeError || this.isFileSizeError || this.isVirusFile
      || this.isFileSameError || this.isFileNameError || this.isFileSizeZero || this.isPassProtectedFile
    ) {
      setTimeout(() => {
        this.displayErrMsg = true;
        if (this.isUploadSuccess || this.isUploadFailure) {
          this.utilsService.setFocus('modalPopupClose');
        }
      }, 1000);
    }
  }

   /**
   * trigger click event for file upload
   */
  triggerClick(obj: any, e?: KeyboardEvent,): void {
    if (e?.code === 'Enter' || (!e && obj)) {
      obj.click();
    }
  }

  /**
   * check the file selected successfully from browser and set flag for ADA message
   */
  checkFileSelected(): any {
    return this.allFileDetails.some((element: FileDetails) => {
      return element.fileName === this.fileUploaded
    });
  }

}
