import { Component, Input, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';

import { ContractDetailsService } from 'src/app/shared/services/contract-details.service';
import { ContractStatusTracker, FundsReceivedMoneyIn, SourceOfFundsUI, TrackerChevron } from 'src/app/shared/models/app.model';
import { TrackerChevronValue } from 'src/config/constants';
import { AnalyticsAppService } from 'src/app/shared/services/analytics.service';

@Component({
  selector: 'app-tracker-step',
  templateUrl: './tracker-step.component.html',
  styleUrls: ['./tracker-step.component.scss']
})
export class TrackerStepComponent implements OnInit {

  @Input() step!: ContractStatusTracker;
  @Input() SourceOfFundsUI!: SourceOfFundsUI[];
  @Input() FundsReceivedMoneyIn!: FundsReceivedMoneyIn[];

  trackerChevron: TrackerChevron = TrackerChevronValue;
  @Input() notNoLOA: boolean | undefined;

  constructor(private viewportScroller: ViewportScroller, private contractDetailsService: ContractDetailsService, private analytics: AnalyticsAppService) {}

  ngOnInit(): void {
    this.viewportScroller.setOffset(() => [0, 150]);
  }

  scrollTo(elementId: string, selectedStep: string): void {
    this.viewportScroller.scrollToAnchor(elementId);
    this.contractDetailsService.setLicensingViewLinkClick(true);
    this.analytics.logAnalytics({
      event_action: 'link',
      event_name: selectedStep,
      event_type: 'pending tool - annuity',
      event_version: 'case tracker'
    })
  }

  getAriaLabelValue(): string {
    return this.step?.ChevronStatus === 'Completed' ? `${this.step?.TrackerChevron} chevron with status completed,  ` : `${this.step?.TrackerChevron} chevron with status pending,  `;
  }
}
