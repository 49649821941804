import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EntAccordionModule } from '@ng/ent-components/ent-accordion';
import { EntButtonTrayModule } from '@ng/ent-components/ent-button-tray';
import { EntUtilitiesModule } from '@ng/ent-utilities';
import { EntFormWidgetsModule } from '@ng/ent-components/ent-form-widgets';
import { EntTablesModule } from '@ng/ent-components/ent-tables';
import { EntModalPopupModule } from '@ng/ent-components/ent-modal-popup';

import { ScrollToTopComponent } from './components/scroll-to-top/scroll-to-top.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NoSpecialSymbolsDirective } from './directives/no-special-symbols.directive';


@NgModule({
  declarations: [
    ScrollToTopComponent,
    NoSpecialSymbolsDirective
  ],
  imports: [
    CommonModule,
    EntAccordionModule,
    EntButtonTrayModule,
    EntUtilitiesModule,
    EntFormWidgetsModule,
    EntTablesModule,
    EntModalPopupModule,
    ReactiveFormsModule
  ],
  exports: [
    EntAccordionModule,
    EntButtonTrayModule,
    EntUtilitiesModule,
    EntFormWidgetsModule,
    EntTablesModule,
    EntModalPopupModule,
    ScrollToTopComponent,
    ReactiveFormsModule,
    NoSpecialSymbolsDirective
  ],
  providers: []
})
export class SharedModule { }
