import { Component, Input } from '@angular/core';

import { EntPrimaryTableConfig } from '@ng/ent-components/ent-tables';
import { EntLoaderService } from '@ng/ent-components/ent-loader';

import { DownloadFileService } from 'src/app/shared/services/download-file.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { OutstandingCommentsTableConfig } from '../outstanding-requirement-form/outstanding-requirement-comments-table-config';
import { Message, OutstandingCommentsTableRowsPerPage } from 'src/config/constants';
import { BRERequirement, File } from 'src/app/shared/models/app.model';
import { environment } from 'src/environments/environment.prod';
import { OutstandingRequirementService } from 'src/app/shared/services/outstanding-requirement.service';
import { AgentService } from 'src/app/shared/services/agent.service';
import moment from 'moment';
import { FileMetadataService } from 'src/app/shared/services/file-metadata.service';


@Component({
  selector: 'app-download-file',
  templateUrl: './download-file.component.html',
  styleUrls: ['./download-file.component.scss']
})
export class DownloadFileComponent {

  @Input() index = 0;
  @Input() BRERequirement!: BRERequirement;
  @Input() outstandingCommentsTableCustomData!: EntPrimaryTableConfig;

  outstandingCommentsTableConfig: EntPrimaryTableConfig = OutstandingCommentsTableConfig;
  outstandingCommentsTableRowsPerPage: number = OutstandingCommentsTableRowsPerPage;
  downloadFileModalConfig = {
    header: true,
    state: true,
    footer: true
  }
  isDownloadSuccess!: boolean;
  isDownloadFailure!: boolean;
  environment: any = environment;
  message = Message;
  isFileSelected = false;
  requirementFileList: any;
  allFileList: any;
  emptyFileListError = false;
  fileMetadata: any;
  requirementFileMetadata: any;

  constructor(private utilsService: UtilsService,
    private downloadFileService: DownloadFileService, private entLoaderService: EntLoaderService, private agentService: AgentService,
    private outstandingReqService: OutstandingRequirementService, private fileMetadataService: FileMetadataService
  ) {}

  ngOnInit() {
    this.fileMetadata = this.fileMetadataService.getFileMetadata()
    this.requirementFileMetadata = this.fileMetadata?.requirements?.find((file:any) => {
      return file.requirementId === this.BRERequirement.ID;
    }).files;
    if (!this.requirementFileMetadata) {
      this.emptyFileListError = true;
      return;
    }
    this.utilsService.setFocus('modalPopupClose');
  }

  /**
   * close download file Modal
   * @returns {void}
   */
  closedownloadFileModal(): void {
    this.downloadFileModalConfig.state = false;
    this.BRERequirement.DownloadFile = {
      ModalState: false,
    }
    this.utilsService.setFocus(`downloadFileBtn-${this.BRERequirement.ID}`);
  }

  /**
   * download selected file api call, on success show success msg for 5 sec and then hide it
   * @returns {void}
   */
  downloadFile(file: File): void {
    this.entLoaderService.show();
    this.resetProps();
    const agentProfile = this.agentService.getAgentProfile();
    const userName = `${agentProfile?.lastName?.trim() || ''}, ${agentProfile?.firstName?.trim() || ''}`;
    const commentData = {
      date:  new Date(),
      userName: userName,
      comment: `(${file.fileName}) was downloaded successfully.`
    }
    this.downloadFileService.downloadFile(file.fileName, this.BRERequirement?.ID).subscribe((res: any) => {
      if (res.responseStatus == "SUCCESS") {
        const fileData = 'data:application/octet-stream;base64,' + res.data[0];
        const downloadLink = document.createElement('a');
        const fileName = file.fileName;
        downloadLink.href = fileData;
        downloadLink.download = fileName;
        downloadLink.click();
        this.entLoaderService.hide();
        file.downloaded = true;
        this.isDownloadSuccess = true;
        this.callAnnuityAPI(this.BRERequirement?.ID || '', commentData);
        // this.utilsService.setFocus('modalPopupClose');
        setTimeout(() => {
          this.isDownloadSuccess = false;
        }, 5000)
        return;
      }
      if (res == '500') {
        this.entLoaderService.hide();
        this.isDownloadFailure = true;
        return;
      }
    })
  }

  /**
   * call ANBA api to add comment for the downloaded file
   * @param id - BRERequirement ID
   * @param commentData - date, username, comment text
   */
  callAnnuityAPI(id: string, commentData: any): void {
    this.outstandingReqService.requirementResolution('Agent', commentData, id || '')
      .subscribe((resp: any) => {
        if (resp === 'success') {
          this.BRERequirement.TableData?.unshift({
            dateandtime: moment(commentData.date).format('MM/DD/YYYY hh:mm A'),
            user: commentData.userName,
            comment: commentData.comment
          })
          this.outstandingCommentsTableCustomData.config.rowsPerPage = this.outstandingCommentsTableRowsPerPage;
          this.setAgentComments(commentData);
          return;
        }
        if (resp == '500') {
          return;
        }
    });
  }

  /**
   * Add file download details to Agent comments object
   * @param commentData - ID, comment text, date, agent name
   */
  setAgentComments(commentData: any): any {
      this.utilsService.getAgentComments().push({
        ID: this.BRERequirement.ID,
        AgentCommentText: commentData.comment,
        AgentCommentDate: moment(commentData.date).format('MM/DD/YYYY hh:mm A'),
        AgentName: commentData.userName
      })
  }

  /**
   * reset success, error message properties
   * @returns {void}
   */
  resetProps(): void {
    this.isDownloadSuccess = false;
    this.isDownloadFailure = false;
  }

  onTab(e: KeyboardEvent, ind?: number): void {
    if (ind != this.requirementFileMetadata?.length - 1) {
      return;
    }
    if (!e.shiftKey && e.code === 'Tab') {
      this.utilsService.setFocus('modalPopupClose');
      e.preventDefault();
    }
  }

}
