import { Injectable } from '@angular/core';
import { Observable, Subject, takeUntil } from 'rxjs';
import moment from 'moment';

import { EntLoaderService } from '@ng/ent-components/ent-loader';

import { ServiceInvoker } from './service-invoker';
import { ContractDetailsService } from './contract-details.service';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class OutstandingRequirementService {

  private unSubscribeSub: Subject<void> = new Subject();

  constructor(private entLoaderService: EntLoaderService, private dataService: ServiceInvoker,
    private contractDetailsService: ContractDetailsService, private utilService: UtilsService
  ) { }

  requirementResolution (type: string, commentData?: any, id?: string): Observable<any> {
    const subject = new Subject();
    const payload = this.getPayload(type, commentData, id || '');
    this.entLoaderService.show();
    this.dataService.resolveRequirements(payload)
      .pipe(takeUntil(this.unSubscribeSub))
        .subscribe({
          next: (response) => {
            if (response.responseStatus === 'SUCCESS') {
              this.entLoaderService.hide();
              subject.next('success');
              return;
            }
            subject.next('500');
            this.entLoaderService.hide();
          },
          error: () => {
            this.entLoaderService.hide();
            subject.next('500');
          },
        });
    return subject as Observable<any>;
  }

  getPayload(type: string, commentData: any, id: string): any {
    const payload: any = {};
    const contractNumber = this.contractDetailsService.getContractNumber();
    payload.userType = 'Agent';
    payload.contractNumber = Number(contractNumber);
    if (type === 'Agent') {
      payload.type = type;
    }
    if (type === 'Attachment') {
      payload.type = type;
      const files = this.utilService.getUploadFileNames();
      const attachment: any = [];
      files?.forEach(file => {
        attachment.push({
          id,
          uploadDate: moment(commentData.date).format('MM/DD/YYYY hh:mm A'),
          fileName: file
        })
      });
      payload.attachment = attachment;
    }
    payload.agentComment = {
      id,
      agentCommentDate: moment(commentData.date).format('MM/DD/YYYY hh:mm A'),
      agentCommentText: commentData.comment,
      agentName: commentData.userName
    }
    return payload;
  }
}


