import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EntModalPopupModule} from '@ng/ent-components/ent-modal-popup';
import { EntLoaderModule } from '@ng/ent-components/ent-loader';


@NgModule({
  imports: [
    CommonModule,
    EntModalPopupModule,
    EntLoaderModule
  ],
  declarations: [],
  exports: [
    EntModalPopupModule,
    EntLoaderModule
  ],
  providers: []
})
export class CoreModule { }
