import { Injectable } from '@angular/core';
import { Observable, of, Subject, takeUntil, map, catchError } from 'rxjs';
import { ServiceInvoker } from './service-invoker';
import { ResponseStatusInterface } from '../models/app.model';
import { ResponseStatus } from 'src/config/constants';
import { ContractDetailsService } from './contract-details.service';

@Injectable({
  providedIn: 'root'
})
export class FileMetadataService {
  unsubscribeSub: Subject<void> = new Subject<any>()
  fileMetadata: any;
  responseStatus: ResponseStatusInterface = ResponseStatus;
  filesToDownload: any = {};

  constructor(
    private dataService: ServiceInvoker, private contractDetailsService: ContractDetailsService
  ) { 
  }

  fetchFileMetadata(): Observable<any> {
    const contractNumber = this.contractDetailsService.getContractNumber();
    return this.dataService.getFileDownloadMetadata(contractNumber).pipe(
      takeUntil(this.unsubscribeSub),
      map((metadata) => {
        if (metadata.data) {
          this.setFileMetadata(metadata.data);
          return this.responseStatus.success;
        }
        return metadata?.error?.errorCode === 401 ? '401' : '500';
      }),
      catchError((res) => of(res?.error?.errorCode === 401 ? '401' : '500'))
    );
  }

  getFileMetadata(): any {
    return this.fileMetadata;
  }

  setFileMetadata(metadata: any): any {
    this.fileMetadata = metadata;
  }

  getRequirementsWithDownload(): Set<any> {
    const reqs = new Set<any>();
  
    this.fileMetadata?.requirements?.forEach((req: any) => {
      if (req.files.some((file: any) => !file.downloaded)) {
        reqs.add(req.requirementId);
      }
    });
  
    return reqs;
  }

  ngOnDestroy(): void {
    this.unsubscribeSub.next();
    this.unsubscribeSub.complete();
  }
}
