<section class="article-component-cont">
  <header>
    <h3 class="ent-color"><i class="fas fa-file-import" aria-hidden="true"></i>Funds received</h3>
  </header>
  <div class="article-content funds-received">
    <!-- funds received table -->
    <ng-content></ng-content>
    <div class="disclaimer">
      <p>
        <strong>* Disclaimer:</strong> {{ disclaimerText.fundsReceived }}
      </p>
    </div>
  </div>
</section>
