import { Component, Input } from '@angular/core';

import { Message } from 'src/config/constants';

@Component({
  selector: 'app-case-tracker',
  templateUrl: './case-tracker.component.html',
  styleUrls: ['./case-tracker.component.scss']
})
export class CaseTrackerComponent {

  @Input() noLOA = false;

  message = Message;

}
