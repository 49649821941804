import { Injectable } from '@angular/core';
import { EntLoaderService } from '@ng/ent-components/ent-loader';
import { Observable, Subject, of, takeUntil } from 'rxjs';
import { ServiceInvoker } from './service-invoker';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ContractDetailsService {
  private unSubscribeSub: Subject<void> = new Subject();
  contractDetails: any;
  contractNumber!: number;
  licensingViewLinkClick: Subject<boolean> = new Subject();

  constructor(
    private entLoaderService: EntLoaderService,
    private dataService: ServiceInvoker
  ) {}

  fetchContractDetails(response: string): Observable<any> {
    if (response === '500') {
      return of(response);
    }
    if (this.contractDetails) {
      return of(response);
    }
    const subject = new Subject();
    const payload: any = {
      userType: 'Agent',
      contractNumber: Number(this.contractNumber)
    };
    if (environment.env != 'prod') {
       payload.pendingAPI = true;
    }
    this.entLoaderService.show();
    this.dataService
      .getContractDetails(payload)
      .pipe(takeUntil(this.unSubscribeSub))
      .subscribe({
        next: (contractDetails) => {
          if (contractDetails.data) {
            this.entLoaderService.hide();
            this.setContractDetails(contractDetails.data);
            subject.next('success');
            return;
          }
          const error = contractDetails?.error?.errorCode == 401 ? '401' : '500'
          subject.next(error);
          this.entLoaderService.hide();
        },
        error: (resp) => {
          this.entLoaderService.hide();
          const error = resp?.error?.errorCode == 401 ? '401' : '500'
          subject.next(error);
        },
      });

    return subject as Observable<any>;
  }

  setContractDetails(contractDetails: any): void {
    this.contractDetails = contractDetails;
  }

  getContractDetails(): any {
    return this.contractDetails;
  }

  setContractNumber(contractNumber: number) {
    this.contractNumber = contractNumber;
  }

  getContractNumber(): number {
    return this.contractNumber;
  }

  setLicensingViewLinkClick(val: boolean) {
    this.licensingViewLinkClick.next(val);
  }

  ngOnDestroy(): void {
    this.unSubscribeSub.next();
    this.unSubscribeSub.complete();
  }
}
