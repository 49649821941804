<section class="article-component-cont">
    <header>
      <h3 class="ent-color"><i class="fas fa-file" aria-hidden="true"></i>Requirements</h3>
    </header>
    <div class="article-content requirement">
      <div aria-live="assertive">
        <ent-alert-message type="info" *ngIf="showPotentialOutstandingMsg && !restrictChaseUser">{{ message.requirementNotAvailable }}</ent-alert-message>
        <ent-alert-message type="warning" *ngIf="showWarningMessage && !restrictChaseUser">{{ message.outstandingRequirementsSubmitInfo }}</ent-alert-message>
        <ent-alert-message type="info" *ngIf="showInfoMessage && !restrictChaseUser">{{ message.outstandingRequirementsInfo }}</ent-alert-message>
      </div>
      <!-- accordion start -->
      <ent-accordion (stateChange)="stateChange($event)" [autoScroll]="false">
        <!-- panel for outstanding requirements -->
        <ent-panel title="Outstanding requirements ({{ outstandingTableConfig.data.length }})" [opened]="accordianState.opened" class="outstanding-requirements">
          <span class="accordion__sub-title-hook">
            <ent-right-button-tray *ngIf="accordianState.opened && outstandingTableConfig.data.length > 0 && !restrictChaseUser">
              <button type="button" routerLink="/requirements/{{contractNumber}}" (click)="triggerAnalytics();" entBorderButton class="resolve-btn">
                Resolve all requirements
                <i class="fas fa-arrow-circle-right" aria-hidden="true"></i>
              </button>
            </ent-right-button-tray>
          </span>
          <div aria-live="assertive">
            <ent-alert-message type="warning" *ngIf="showDownloadWarningMessage && !restrictChaseUser">{{ message.fileDownload }}</ent-alert-message>
          </div>
          <!-- outstanding requirements table -->
          <ent-primary-table [tableData]="outstandingTableConfig" (tableCellClick)="tableCellClick($event)">
            <ng-template #tableCelContentTemplate let-column="column" let-row="row">
              <div *ngIf="column.columnKey === 'Action'">
                <a (click)="tableCellClick({data: row})" class="policy-link">RESOLVE</a>
                  <span *ngIf="requirementsWithDownload.has(row.ID)">
                    <i aria-hidden="true" class="fa-exclamation-triangle fas fileDownload"></i>
                    <span class="screen-reader-only">warning icon</span>
                  </span>
              </div>
              </ng-template>
          </ent-primary-table>
        </ent-panel>
        <!-- panel for completed requirements -->
        <ent-panel title="Completed requirements ({{ completedTableConfig.data.length }})">
          <!-- completed requirements table -->
          <ent-primary-table [tableData]="completedTableConfig" (tableCellClick)="tableCellClick($event)"></ent-primary-table>
        </ent-panel>
      </ent-accordion>
      <!-- accordion ends -->
    </div>
</section>
