import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PolicyDetailsComponent } from './contract/policy-details/policy-details.component';
import { OutstandingRequirementsComponent } from './contract/outstanding-requirements/outstanding-requirements.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'contractdetails'
  },
  {
    path: 'contractdetails',
    pathMatch: 'full',
    component: PolicyDetailsComponent
  },
  {
    path: 'requirements/:contractNumber',
    pathMatch: 'full',
    component: OutstandingRequirementsComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, anchorScrolling: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
