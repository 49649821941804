<div>
  <span>
    <span class="screen-reader-only">{{ getAriaLabelValue() }}</span>
    <span class="tracker-step" *ngIf="step" aria-hidden="true"
      [ngClass]="{'completed': step.ChevronStatus == 'Completed', 'inprogress': step.ChevronStatus == 'Pending'}">
      <i [ngClass]="{ 'fas fa-check': step.ChevronStatus == 'Completed', 'far fa-times-circle': step.TrackerChevron == 'Not in good order',
                      'far fa-clock': step.ChevronStatus == 'Pending'}"></i>
      {{ step.TrackerChevron }} &nbsp;
      <span *ngIf="step.StatusDate != ''" class="mobile-view">| &nbsp;
        <span class="screen-reader-only">status date, </span>
        {{ step.StatusDate | customDate:'MM/DD/YYYY' }}
      </span>
    </span>
  </span>

  <span *ngIf="step" class="status-date">
    <span *ngIf="step.StatusDate != ''" class="no-mobile-view">
      <span class="screen-reader-only">status date, </span>
      {{ step.StatusDate | customDate:'MM/DD/YYYY' }}
    </span>
    <span>
      <span *ngIf="step.TrackerChevron == trackerChevron.Licensing && step.ChevronStatus !== 'Completed'" class="screen-reader-only" aria-live="polite">
        on view link click page will scroll to outstanding requirements section
      </span>
      <a href="javaScript:void(0)" *ngIf="step.TrackerChevron == trackerChevron.Licensing && step.ChevronStatus !== 'Completed'" (click)="scrollTo('outstanding-requirements', step.TrackerChevron)">
        View
      </a>
    </span>

    <ng-container *ngIf="SourceOfFundsUI && SourceOfFundsUI.length > 0">
      <span
        *ngIf="step.TrackerChevron == trackerChevron.TransferInitiated || step.TrackerChevron == trackerChevron.FundsReceived || step.TrackerChevron == trackerChevron.ContractIssued"
        class="screen-reader-only" aria-live="polite">
        on view link click page will scroll to source of fund section
      </span>
      <a href="javaScript:void(0)"
        *ngIf="step.TrackerChevron == trackerChevron.TransferInitiated && notNoLOA || step.TrackerChevron == trackerChevron.FundsReceived && notNoLOA || step.TrackerChevron == trackerChevron.ContractIssued"
        (click)="scrollTo('source-of-fund', step.TrackerChevron)">
        View
      </a>
    </ng-container>
    <ng-container *ngIf="FundsReceivedMoneyIn && FundsReceivedMoneyIn.length > 0 && !SourceOfFundsUI">
      <span
        *ngIf="step.TrackerChevron == trackerChevron.FundsReceived && notNoLOA || step.TrackerChevron == trackerChevron.ContractIssued"
        class="screen-reader-only" aria-live="polite">
        on view link click page will scroll to funds received section
      </span>
      <a href="javaScript:void(0)"
        *ngIf="step.TrackerChevron == trackerChevron.FundsReceived && notNoLOA || step.TrackerChevron == trackerChevron.ContractIssued"
        (click)="scrollTo('funds-received', step.TrackerChevron)">
        View
      </a>
    </ng-container>
  </span>
</div>
