import { EntPrimaryTableConfigInterface } from "src/app/shared/models/app.model";
import { TableConfig } from "src/config/constants";

export const ClientInfoTableConfig: EntPrimaryTableConfigInterface = {

  config: {
    tableId: 'client-info',
    tableClass: 'client-info-table',
    tableCaption: 'client information table',
    ...TableConfig
  },
    column: [
      {
        type: 'string', // string|number|email
        align: 'left',
        columnKey: 'RoleType',
        displayText: 'ROLE',
        isAction: 'noaction',
        pipeName: '',
        sorting: true,
      },
      {
        sorting: true,
        isAction: 'noaction',
        pipeName: '',
        type: 'string', // string|number|email
        align: 'left',
        displayText: 'NAME',
        columnKey: 'FullName',
      },
      {
        isAction: 'noaction',
        columnKey: 'Email',
        displayText: 'EMAIL ADDRESS',
        pipeName: '',
        type: 'string', // string|number|email
        align: 'left',
        sorting: true,
      },
      {
        isAction: 'noaction',
        pipeName: 'NumberFormatPipe',
        columnKey: 'PhoneNumber',
        displayText: 'PHONE NUMBER',
        type: 'string', // string|number|email
        align: 'left',
        sorting: true,
      }
    ],
    data: [],
};
