<a (click)="backToSearchResults()" tabindex="0">
  <i class="far fa-arrow-alt-circle-left" aria-hidden="true"></i>
  Back to search results
</a>
<ng-container *ngIf="apiStatus$ | async as status">
  <h2>Contract #: {{ contractNumber }} details</h2>
  <div *ngIf="status === responseStatus.error500">
    <ent-alert-message type="error">{{ message.applicationDown }}</ent-alert-message>
  </div>
  <div *ngIf="status === responseStatus.error401">
    <ent-alert-message type="error">{{ message.unAuthorizedError }}</ent-alert-message>
  </div>
  <section *ngIf="status === responseStatus.success">

    <!-- case tracker -->
      <app-case-tracker [noLOA]="noLOA" *ngIf="contractTrackerData && contractTrackerData.length > 0">
        <span class="screen-reader-only">tracker with {{ contractTrackerData.length }} chevrons</span>
        <app-tracker-step *ngFor="let trackerStep of contractTrackerData" class="tracker"
          [step]="trackerStep"
          [SourceOfFundsUI]="contractData.SourceOfFundsUI"
          [FundsReceivedMoneyIn]="contractData.FundsReceivedMoneyIn"
          [notNoLOA]="notNoLOA"
          [mobileView]="mobileView">
        </app-tracker-step>
      </app-case-tracker>

    <!-- client information -->
      <app-client-information *ngIf="clientInfoTableConfig?.data && clientInfoTableConfig.data.length > 0">
        <ent-primary-table [tableData]="clientInfoTableConfig"></ent-primary-table>
      </app-client-information>

    <!-- requirements -->
      <app-requirement [requirementData]="requirementsData" id="outstanding-requirements"></app-requirement>

    <!-- agent information -->
      <app-agent-information *ngIf="agentInfoTableConfig?.data && agentInfoTableConfig.data.length > 0 " [agentData]="agentData" aria-live="polite">
        <ng-container *ngIf="agentInfoTableConfig.data.length > 3">
          <ent-primary-table [tableData]="agentInfoTableConfig"></ent-primary-table>
          <a *ngIf="agentInfoTableConfig.config.rowsPerPage !== agentInfoTableConfig.data.length" id="viewall"
            href="javascript:void(0)" (click)="viewAllrows()" aria-expanded="false">
            View all {{ agentInfoTableConfig.data.length }}
            <i class="fas fa-caret-down" aria-hidden="true"></i>
          </a>
          <a *ngIf="agentInfoTableConfig.config.rowsPerPage === agentInfoTableConfig.data.length" id="viewless"
            href="javascript:void(0)" (click)="viewLessrows()" aria-expanded="true">
            View less
            <i class="fas fa-caret-up" aria-hidden="true"></i>
          </a>
        </ng-container>
      </app-agent-information>

    <!-- contract and rate lock information-->
      <div class="contract-and-rate-lock-info">
        <app-contract-information>
          <ent-primary-table [tableData]="contractInfoTableConfig"></ent-primary-table>
        </app-contract-information>
        <app-rate-lock-information [rateLockInfo]="rateLockInfo" *ngIf="rateLockInfo?.effectiveDate">
        </app-rate-lock-information>
      </div>

    <!-- source of fund -->
      <app-source-of-fund *ngIf="sourceOfFundsTableConfig?.data && sourceOfFundsTableConfig.data.length > 0" id="source-of-fund">
        <ent-primary-table [tableData]="sourceOfFundsTableConfig"></ent-primary-table>
      </app-source-of-fund>

    <!-- fund received -->
      <app-fund-received *ngIf="fundsReceivedTableConfig?.data && fundsReceivedTableConfig.data.length > 0" id="funds-received">
        <ent-primary-table [tableData]="fundsReceivedTableConfig"></ent-primary-table>
      </app-fund-received>

  </section>
</ng-container>

