<section class="outstanding-requirements" *ngIf="apiStatus$ | async as status">
  <a (click)="backToAnnuityPendingDetails()" tabindex="0">
    <i class="far fa-arrow-alt-circle-left" aria-hidden="true"></i>
    Back to Annuity pending details
  </a>
  <h2 id="pageheader">Outstanding requirements</h2>
  <div *ngIf="status === responseStatus.error500">
    <ent-alert-message type="error">{{ message.applicationDown }}</ent-alert-message>
  </div>
  <div *ngIf="status === responseStatus.error401">
    <ent-alert-message type="error">{{ message.unAuthorizedError }}</ent-alert-message>
  </div>
  <section *ngIf="status === responseStatus.success">
    <p>Use the form below to upload comments or files related to the outstanding requirements for this account.</p>
    <p class="info"><strong>Contract #: {{ contractNumber }} &nbsp;|&nbsp; Owner:</strong> {{ ownerName }}</p>
    <section *ngIf="displayFYI" class="article-component-cont border-bottom no-padding">
      <header class="no-border">
        <h3 class="ent-color"><i class="fas fa-file-alt" aria-hidden="true"></i>FOR YOUR INFORMATION</h3>
        <p class="padding-left">The following outstanding requirements are for informational purposes only and do not require comments or files for resolution.</p>
      </header>
      <div class="article-content">
        <ent-alert-message type="info">{{ outstandingRequirementsFYIInfo }}</ent-alert-message>
      </div>
    </section>

    <section *ngIf="isBRERequirements" class="article-component-cont border-bottom padding-bottom no-margin">
      <header class="no-border">
        <div *ngIf="allRequirementsResolved">
          <h3 class="ent-color"><i class="fas fa-file-medical" aria-hidden="true"></i>ACTION NEEDED</h3>
          <p class="padding-left">
            To resolve the following outstanding requirements, please enter information for each requirement individually.
            If you need to provide a form, please use the upload file button.
          </p>
        </div>
        <ent-alert-message *ngIf="allRequirementsResolved" type="info" class="char-info">{{ message.addCommentSpeacialCharInfo }}</ent-alert-message>
        <div *ngIf="!allRequirementsResolved">
          <h3 class="ent-color"><i class="fas fa-file-medical" aria-hidden="true"></i>ALL OUTSTANDING REQUIREMENTS RESOLVED</h3>
          <p class="padding-left">
            You no longer have any outstanding requirements to resolve. The below information is for your reference only, no action is required.
            Thank you for choosing Lincoln Financial.
          </p>
        </div>
      </header>
    </section>
    <app-outstanding-requirement-form *ngIf="BRERequirements" [BRERequirements]="BRERequirements"></app-outstanding-requirement-form>
  </section>
</section>
