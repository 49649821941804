import { HttpClient } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { EntRestClientService } from '@ng/ent-utilities';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SFS_CONFIG } from 'src/config/constants'

@Injectable({
  providedIn: 'root'
})
export class ServiceInvoker {

  constructor(private rs: EntRestClientService, private http: HttpClient) {}

  private isDev = isDevMode();

  getAgentProfile(payload: any): Observable<any> {
    const url = this.isDev
      ? environment.serviceUrls.agentProfileMock
      : environment.serviceUrls.apiUrl + '/agentProfile';
    return this.rs.makeCall<any>(url, payload);
  }

  getContractDetails(payload: any): Observable<any> {
    const url = this.isDev
      ? environment.serviceUrls.contractDetailsMock
      : environment.serviceUrls.apiUrl + '/nbpendingcontractdetails';
    return this.rs.makeCall<any>(url, payload);
  }

  resolveRequirements(payload: any): Observable<any> {
    const url = this.isDev
      ? environment.serviceUrls.requirementResolution
      : environment.serviceUrls.apiUrl + '/requirements-resolution';
    return this.rs.makeCall<any>(url, payload);
  }

  uploadFiles(payload: any): Observable<any> {
    const url = this.isDev
      ? environment.serviceUrls.uploadFiles
      : environment.serviceUrls.sfsApiUrl + '/uploadFileSecure';
    return this.isDev ? this.http.get<any>(url) : this.http.post<any>(url, payload, {
      headers: this.getSFSCallHeaders(),
      reportProgress: true,
      observe: 'events'
    });
  }

  pwdProtectFile(payload: any): Observable<any> {
    const url = this.isDev
      ? environment.serviceUrls.uploadFiles
      : environment.serviceUrls.sfsApiUrl + '/uploadFileSecure';
    return this.isDev ? this.http.get<any>(url) : this.http.post<any>(url, payload, { headers: this.getSFSCallHeaders() });
  }

  getSFSCallHeaders(): any {
    return {
      'AWS_ENV': environment.env,
      'X-application-name': SFS_CONFIG.HEADER_KEYS.X_APPLICATION_NAME,
      'WSUID': '',
      'APM_ID': SFS_CONFIG.APM_ID,
      'CALLER': SFS_CONFIG.HEADER_KEYS.CALLER,
      'CLIENT': SFS_CONFIG.HEADER_KEYS.CLIENT,
      'CHANNEL': SFS_CONFIG.HEADER_KEYS.CHANNEL
    }
  }
}
