import { Injectable, isDevMode } from '@angular/core';

import { EntAnalyticsEventData, EntAnalyticsService, EntWindowRefService } from '@ng/ent-utilities';
import { AgentService } from './agent.service';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsAppService {
  env = 'dev';
  window: any;
  isDev = isDevMode();

  constructor(
    private analyticsService: EntAnalyticsService,
    private windowRef: EntWindowRefService, private agentService: AgentService
  ) {
    this.window = this.windowRef.getWindow();
  }

  getAnalyticsEventData(isErr: boolean): any {
    const analyticsEventData = new EntAnalyticsEventData();
    analyticsEventData.event_version = '';
    return analyticsEventData;
  }

  logAnalytics(
    data: {
      event_action?: string;
      event_name?: string;
      event_type?: string;
      event_version?: string;
      event_status?: string;
    },
    error = false
  ): any {
    const analyticsEventData = this.getAnalyticsEventData(error);
    this.analyticsService.link({ ...analyticsEventData, ...data });
  }

  logPageView(pageName: string): void {
    this.analyticsService.setUTagProperty('page_l1', 'secure');
    this.analyticsService.setUTagProperty('page_l2', 'producer');
    this.analyticsService.setUTagProperty('page_l3', 'annuity pending'); 
    if (pageName == 'contract details') {
      this.analyticsService.setUTagProperty('page_l4', pageName);
    }
    if (pageName == 'outstanding requirements') {
      this.analyticsService.setUTagProperty('page_l4', 'contract details');
      this.analyticsService.setUTagProperty('page_l5', pageName);
    }
    this.analyticsService.setUTagProperty('user_id', this.agentService.getAgentProfile()?.proxyGuid);
    this.analyticsService.setUTagProperty('user_role', this.agentService.getAgentProfile()?.role);
    this.analyticsService.setUTagProperty('user_hub_role', this.agentService.getAgentProfile()?.hubRole);
    this.analyticsService.setUTagProperty('page_security', 'secure');
    this.analyticsService.triggerUTagCollection();
  }
}
