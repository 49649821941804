export const Message = {
    applicationDown: 'The Lincoln annuity pending application is currently unavailable. We are working to resolve this issue and apologize for any inconvenience. Please try again later.',
    unAuthorizedError: 'We\'re sorry but you are not authorized to view the details of this contract. Please navigate back to your search results page to see the list of contracts you are authorized to view.',
    outstandingRequirementsSubmitInfo: 'To move this contract into good order, you must submit information for each outstanding requirement.',
    noLOA: 'Please provide transfer paperwork if Lincoln is to initiate the transfer. Otherwise please provide an estimated date when and from whom the funds should be received. If the contract is not funded within 6 months of the application sign date, Lincoln will consider it to be stale dated and will require a new application and paperwork to be submitted.',
    outstandingRequirementsInfo: 'There are some outstanding requirements that are for informational purposes only and do not require further action. If you would like to review them, please select the REVIEW link in the action column.',
    outstandingRequirementsFYInfo: 'Please note: Lincoln is currently validating producer appointment and licensing requirements as it is the process on each contract. At this time, there is nothing outstanding, but should a requirement be needed, it will be communicated promptly.',
    requirementNotAvailable: 'Potential outstanding requirements found. Should resolution be needed, your Case Coordinator will reach out and note the case accordingly. Updates can be expected within 48 hours.',
    requirementWarningMsg: 'You have a comment that has not been saved. Before completing this requirement, please select the ADD COMMENT button or remove your comment from the comment box.',
    requirementSuccessMsg: 'Your comment has been added successfully.',
    requirementErrorMsg: 'We\'re sorry, there was an issue adding your comment please try again. If you continue to face an issue, please call 877-533-1022.',
    addCommentSpeacialCharInfo: "Please note: When adding a comment, only use the following characters a-z, A-Z, 0-9,-.' ;:/@$?%()_. Thank you.",
    addCommentSpeacialChar: "When adding a comment, please only use the following characters a-z, A-Z, 0-9,-.' ;:/@$?%()_. Please remove any disallowed characters and try again.",
    fileUploadSuccess: 'Your file has been successfully uploaded and a record of this has been stored in the comments below.',
    fileUploadFailure: 'We apologize an error has occurred while trying to upload your file. Your file has not been saved. Please try again, if you continue to face an issue, please contact our web support team at 877-533-1022.',
    fileSizeError: 'Your files exceed the 30MB maximum. Please resend with fewer or smaller files.',
    fileTypeError: "is an invalid file type. Authorized file types are pdf, gif, jpeg, jpg, bmp, tif, tiff, doc, docx, xls and xlsx. Please modify the file and resubmit.",
    fileNameError: 'is having an invalid file name. A file name cannot contain one of these characters: ` ~ ! @ # $ % ^ & * ( ) + = [ ] { } \ | : ; \' ", < > / ?. Please rename the files and resubmit.',
    filePassProtectedError: 'is password protected. This file will not transmit correctly in our image system. Please remove the password and try again.',
    emptyFileListError: "Currently no files are available for download.",
    fileDownload: "You have file(s) to review. Please click the resolve link to do so."
}

export const ResponseStatus = {
    success: 'success',
    error500: '500',
    error401: '401'
}

export const TableConfig = {
    sorting: false,
    defaultSortKey: 'col1',
    pagination: false,
    rowsPerPage: 10,
    tableFoot: false,
    defaultSortOrder: 'desc',
    clickSortOrder: 'asc',
    noRecordsText: 'No search results found.',
    handleSubDetailRow: false,
    currentPage: 0,
    addNewRowLink: false,
    deleteIndRowLink: false,
    handleDelNRowInApp: false,
    handleAddNRowInApp: false,
    isInteractive: false,
    groupByField: '',
    groupByFieldHeader: 'groupFieldHeader'
  }

export const TrackerChevronValue = {
  Licensing: 'Licensing',
  TransferInitiated: 'Transfer initiated',
  FundsReceived: 'Funds received',
  ContractIssued: 'Contract issued'
}

export const OutstandingCommentsTableRowsPerPage = 3;

export const AppConstants = {
  SITE_TYPE: 'secure',
  SITE_NAME: 'siteName',
  HUB_PENDING: '/pendingbusiness',
  ACCOUNT_LOOKUP: '/homela#/findAccountContent'
}

/* Window Nav constants */
export const SECURE_SITES = {
  'SecureProducer': '/producer',
  'SecureInternal': '/internal',
  'SecureConsumer': '/consumer',
  'SecurePlanSponsor': '/employer',
  'SecureRIA': '/ria'
}

export const fileTypes = [
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "image/gif",
  "image/jpeg",
  "image/bmp",
  "image/tiff"
];

export const SFS_CONFIG = {
  APM_ID: '08442d8037de9a40007b2863b3990eb1',
  ROLE: 'ISVC',
  SFS_PASWRD_PROTECTED_PAYLOAD: 'AWS_SFS_PASSWORD_PROTECTED',
  SFS_UPLOAD_FILE_PAYLOAD: 'AWS_SFS_UPLOAD_FILE',
  SUBMISSION_TYPE: 'annuityPending',
  HEADER_KEYS: {
    X_APPLICATION_NAME: `SFS`,
    CALLER: `SFS`,
    CLIENT: `ANN`,
    CHANNEL: `WEB`
  },
  VIRUS_SCAN_FILE_ERROR_KEY: 'File uploaded are malicious and contain virus.',
}

export const Disclaimer = {
  sourceOfFund: 'Transfer/Exchange information is not shown until transfer forms are received. Transfer forms deemed In Good Order will be sent to the surrendering carrier. Receipt of funds is dependent on review and release by the surrendering carrier.',
  fundsReceived: 'For single premium Fixed/Fixed Indexed/Registered Linked Annuity products, funds received will show as Pending until all expected funds are received.',
  sourceOfFundSentDate: 'The paperwork sent date indicates the original date on which the transfer paperwork was sent to the surrendering carrier. Please note that if the paperwork is resent, the paperwork sent date will remain unchanged.'
}
