import { EntPrimaryTableConfigInterface } from "src/app/shared/models/app.model";
import { TableConfig } from "src/config/constants";

export const AgentInfoTableConfig: EntPrimaryTableConfigInterface = {
  config: {
    tableId: 'agent-info',
    tableClass: 'agent-info-table',
    tableCaption: 'agent information table',
    ...TableConfig,
    rowsPerPage: 3,
    pagination: true,
  },
  column: [
    {
      displayText: 'AGENT',
      columnKey: 'AgentName',
      isAction: 'noaction',
      pipeName: '',
      type: 'string', // string|number|email
      align: 'left',
      sorting: true,
    },
    {
      displayText: 'SSN',
      columnKey: 'Last4AgentSSN',
      isAction: 'noaction',
      pipeName: '',
      type: 'innerHTML', // string|number|email
      align: 'left',
      sorting: true,
    }
  ],
  data: [],
};
