import { Component } from '@angular/core';

import { Disclaimer } from 'src/config/constants'

@Component({
  selector: 'app-fund-received',
  templateUrl: './funds-received.component.html'
})
export class FundsReceivedComponent {
  disclaimerText = Disclaimer;
}
