<section class="article-component-cont">
  <header>
    <h3 class="ent-color"><i class="fas fa-briefcase" aria-hidden="true"></i>Case tracker</h3>
  </header>
  <div class="article-content">
    <ent-alert-message type="warning" *ngIf="noLOA">{{ message.noLOA }}</ent-alert-message>
    <div class="case-tracker">
      <!-- case tracker steps -->
      <ng-content></ng-content>
    </div>
  </div>
</section>
