import { ValidatorFn, AbstractControl } from '@angular/forms';

/**
 * @returns ValidatorFn
 */
export function textAreaValidator(): ValidatorFn {
  return (control: AbstractControl): any => {
    let textarea = control ? control.value : null;
    if (!textarea) {
      return null;
    }
    if (textarea.length > 0 && textarea.trim().length == 0) {
      return { textareaError: true };
    }
    textarea = textarea.replace(/\n/g, '');
    textarea = textarea.replace(/\s/g, '');
    const textPatt = new RegExp(/^[a-zA-Z0-9 ,/.':;@$?%()_-]*$/); // a-z, A-Z, 0-9,-.' @$?%()_
    return textPatt.test(textarea.trim()) ? null : { textareaError: true };
  };
}
