import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { ContractDetailsService } from './shared/services/contract-details.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'annuities-pending-ui';

  constructor(private authService: AuthService, private contractDetailsService: ContractDetailsService, private route: ActivatedRoute ) {}

  ngOnInit(): void {
    this.authService.error$.subscribe((_error) => {
      this.authService.loginWithRedirect();
    });
    this.route.queryParamMap.pipe().subscribe((p)=>{
      const accountlookupFlag = p.get('accountlookup') == 'true';
      if(accountlookupFlag) {
        this.contractDetailsService.setAccountlookupFlag(accountlookupFlag);
      }
    })
  }
}
