import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';


if (environment.production) {
  if (window) {
    window.console.log = () => {
      // remove the console logs
    };
    window.console.group = () => {
      // remove the console groups
    };
  }
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(_err =>
    console.log('some error')
  );
