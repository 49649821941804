<!-- Main content -->
<ent-loader></ent-loader>
<main role="main" class="no-margin-top">
    <div class="u-global-container">
      <article class="main-content main-content--full" data-event-type="mclink">
        <section class="content-area">
            <router-outlet></router-outlet>
        </section>
      </article>
    </div>
</main>
<app-scroll-to-top></app-scroll-to-top>
